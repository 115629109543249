import React, { useContext, useEffect, useState } from 'react'
import styles from './counterComponent.module.css'
import IconComponent from '../icons/IconComponent'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { commandRequest } from '../../../context/actions/zoneActions'
import { GlobalContext } from '../../../context/provider'

const CounterComponent = ({deviceData:{device,digispace,asset_id,id},counterInitialValue,setCounterInitialValue}) => {
  const [counterValue, setCounterValue] = useStateWithCallbackLazy(counterInitialValue)
  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    setCounterInitialValue(counterValue)
  },[counterValue])
  
  const handleIncrease = () => {
    setCounterValue(prev => prev + 1,(value) => {
      const commandRequestPayload = {
        AssetId: id,
        Properties: device.properties.map((property,index) => {
          if(property.label === 'Temperature'){
            return {
              ...property,
              value: value 
            }
          }
          else {
            return property
          }
        }),
        
        RequestCommand: {
          action: "temp",
          action_values: ["power", "temp"],
          executed: false,
          meta: {
            asset_id: asset_id,
            line_id: digispace.properties[0].value,
            live_response: true,
            unit_id: digispace.properties[1].value
          },
          type: "SET",
          value: value 
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
      
      commandRequest(dispatch,commandRequestPayload)
    })
  }
  const handleDecrease = () => {
    setCounterValue(prev => {
      if(counterValue !== 0) return prev - 1
      else return 0
    },(value) => {
      const commandRequestPayload = {
        AssetId: id,
        Properties: device.properties.map((property,index) => {
          if(property.label === 'Temperature'){
            return {
              ...property,
              value: value 
            }
          }
          else {
            return property
          }
        }),
        
        RequestCommand: {
          action: "temp",
          action_values: ["power", "temp"],
          executed: false,
          meta: {
            asset_id: asset_id,
            line_id: digispace.properties[0].value,
            live_response: true,
            unit_id: digispace.properties[1].value
          },
          type: "SET",
          value: value 
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
      
      commandRequest(dispatch,commandRequestPayload)
    })
  }
  return (
    <div className={styles.counter__container}>
      <div className={styles.counter__container__label}>Set Point</div>
      <div className={styles.counter__container__controls}>
        <div className={styles.counter__container__control} onClick={handleDecrease}>
          <IconComponent name='minus' width={24} height={24} color={'#ffffff'} />
        </div>
        <div className={styles.counter__container__valueContainer}>
          <span className={styles.counter__container__value}>{counterValue}</span>
          <span className={styles.counter__container__unit}>&deg;C</span>
        </div>
        <div className={styles.counter__container__control} onClick={handleIncrease}>
          <IconComponent name='plus' width={24} height={24} color={'#ffffff'} />
        </div>

      </div>
    </div>
  )
}

export default CounterComponent