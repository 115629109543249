import { COMMAND_REQUEST, GET_ASSETLIST, GET_BDASSETLIST, GET_FLOOR, GET_FLOORLIST, GET_FLOOR_ASSETLIST, GET_FLOOR_ROOMLIST, GET_SITELIST, LOADING } from "../../constants/types";

const reducer = (state,{type,payload}) => { 
  switch (type) {
    case LOADING:
      return{
        ...state,
        loading:true
      }
    case GET_SITELIST:
      return{
        ...state,
        viewSiteList: payload,
        // loading:false
      }
    case GET_FLOORLIST:
      return{
        ...state,
        floors: payload,
        // loading:false
      }
    case GET_ASSETLIST:
      return{
        ...state,
        assets: payload,
        // loading:false
      }
    case GET_FLOOR:
      return{
        ...state,
        floor: payload,
        // loading:false
      }
    case GET_FLOOR_ROOMLIST:
      return{
        ...state,
        floorRooms: payload,
        // loading:false
      }
    case GET_FLOOR_ASSETLIST:
      return{
        ...state,
        floorAssets: payload,
        loading:false
      }
    case GET_BDASSETLIST:
      return {
        ...state,
        zoneBdAssets: payload,
        loading:false
      }
    case COMMAND_REQUEST:
      return {
        ...state,
        loading:false
      }
    default: 
      return state
  }
}

export default reducer