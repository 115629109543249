import './App.css'
import MainRoutes from "./routes/MainRoutes";

function App() {
  return (
    <div data-testid="app">
      <MainRoutes/>
    </div>
  );
}
export default App;
