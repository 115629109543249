import React from "react";
import styles from "./Input.module.css";
import IconComponent from "../icons/IconComponent";

const Input = ({
  icon,
  name,
  type,
  placeholder,
  setfocus,
  focus,
  handleChange,
  value,
}) => {
  return (
    <div className={styles.container}>
      {/* icon import from iconComponents */}
      <IconComponent
        name={icon}
        width={16}
        height={12}
        color={focus ? "#5b67eb" : "rgba(24, 24, 24, 0.6)"}
      />
      {/* input for write */}
      <input
        placeholder={placeholder}
        className={styles.input}
        type={type}
        name={name}
        value={value}
        data-testid="input"
        onFocus={() => setfocus(true)}
        onBlur={() => setfocus(false)}
        onChange={(e) => handleChange(e)}
        required
      />
    </div>
  );
};

export default Input;
