const initialState = {
  viewSiteList: [],
  floors: [],
  assets: [],
  floor: {},
  floorRooms: [],
  floorAssets: [],
  zoneBdAssets:[],
  loading: false,
}

export default initialState