import { API_BASE_URL } from "../../constants/apiConstants";
import { COMMAND_REQUEST, GET_BDASSETLIST, LOADING } from "../../constants/types";
import http from "../../services/httpService";

export const getBdAssetList = async(dispatch,zoneInfo) => {
  http.setHeaders()
  dispatch({type:LOADING})
  const bdAssetList = await http.post(`${API_BASE_URL}/assetapi/getRoomBdassetlist`,zoneInfo)
  dispatch({
    type: GET_BDASSETLIST,
    payload: bdAssetList.data
  })
}

export const commandRequest = async(dispatch,commandRequestPayload) => {
  http.setHeaders()
  dispatch({type:LOADING})
  const commandRequestMessage = await http.post(`${API_BASE_URL}/assetapi/CommandRequest`,commandRequestPayload)
  dispatch({
    type: COMMAND_REQUEST,
  })
}