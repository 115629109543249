export const setLocalStorage = (data) => { 
  const {UserContext,accesstoken,token} = data

  localStorage.setItem('currentUser',JSON.stringify(UserContext))
  localStorage.setItem('accessToken',accesstoken)
  localStorage.setItem('token',token)
  localStorage.setItem('Email',UserContext.Email)
  localStorage.setItem('UserName',UserContext.Name)
  localStorage.setItem('AccountId',UserContext.accountId)
  localStorage.setItem('SiteId',UserContext.siteId[0])
}