import React, { forwardRef, useState } from 'react'
import styles from './sidebar.module.css'

const Sidebar = forwardRef(({children,color,deviceOn,deviceState},ref) => {
  return (
    <div className={styles.sidebar} style={{backgroundColor:color}} ref={ref}>
      {children}
    </div>
  )
})

export default Sidebar