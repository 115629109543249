import axios from "axios";


export const setHeaders = () => {
  axios.defaults.headers.post = {
    'Authorization' : `Bearer ${localStorage.getItem('accessToken')}`,
    'x-access-token' : localStorage.getItem('accessToken')
  }
};

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setHeaders,
};