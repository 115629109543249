import React, { useState } from "react";
import IconComponent from "../../common/icons/IconComponent";
import styles from "./airConditioner.module.css";
import CounterComponent from "../../common/counterComponent/CounterComponent";
import ToggleButton from "../../common/toggleButton/ToggleButton";

const AirConditioner = ({
  setOpenSidebar,
  setComponents,
  sidebarColor,
  setSidebarColor,
  setCurrentSidebar,
  deviceOn,
  setDeviceOn,
  deviceDetail,
  setSetPointValue,
  setPointValue
}) => {

  /* handleClick passes all the data for AirConditioner sidebar to render */
  const handleClick = () => {
    setCurrentSidebar('Air Conditioner')
    setOpenSidebar(true);
    setComponents({
      // status: deviceOn ? "Device is On" : "Device is Off",
      // statusIcon: deviceOn ? (
      //   <IconComponent
      //     name="ellipse"
      //     width={16}
      //     height={16}
      //     color={"#31BF8C"}
      //   />
      // ) : (
      //   <IconComponent
      //     name="ellipse"
      //     width={16}
      //     height={16}
      //     color={"#F3C624"}
      //   />
      // ),
      icon: "thermometer",
      title: "Temperature Controller",
      temp: 22,
      counter: <CounterComponent deviceData={deviceDetail} counterInitialValue={setPointValue} setCounterInitialValue={setSetPointValue}/>,
      toggle: <ToggleButton toggle={setDeviceOn} value={deviceOn} color={"#FE5D70"} setColor={setSidebarColor} deviceData={deviceDetail}/>,
      device: "Air Conditioner",
    });
    setSidebarColor(deviceOn ? "#FE5D70" : '#BFBFBF');
  };


  return (
    <div className="card-widget-devices" onClick={handleClick}>
      {/* Device Heading */}
      <div className="card-widget-devices__heading">
        <IconComponent
          name="thermometer"
          width={16}
          height={16}
          color={"#FE5D70"}
        />
        <h4 className="card-widget-devices__title">{deviceDetail.asset_name}</h4>
      </div>
      
      {/* Device data */}
      <div className={styles.cardWidgetDevices__content}>
        <div className={styles.cardWidgetDevices__content__temperature}>
          <span className={styles.cardWidgetDevices__content__temperatureValue}>
            {deviceDetail.device.properties[1].value ? deviceDetail.device.properties[1].value : 0}
          </span>
          <span className={styles.cardWidgetDevices__content__temperatureUnit}>
            &deg;C
          </span>
        </div>
        <div className={styles.cardWidgetDevices__content__setPoint}>
          <span className={styles.cardWidgetDevices__content__setPointText}>
            Set Point
          </span>
          <span className={styles.cardWidgetDevices__content__setPointValue}>
            {setPointValue}&deg;C
          </span>
        </div>
      </div>
    </div>
  );
};

export default AirConditioner;
