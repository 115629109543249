import React, { useContext, useState } from 'react'
import IconComponent from '../../common/icons/IconComponent'
import styles from './lighting.module.css'
import { GlobalContext } from '../../../context/provider'

const Lighting = ({activeWidget,setactiveWidget,setActiveWidgetColor}) => {
  const [selected, setSelected] = useState(false)
  const { state, dispatch } = useContext(GlobalContext);
  const lights =  state.floorAssets.res.filter((asset)=> asset.device.subtype === 'light')
  const total = lights.length
  const activelights = lights.filter((light)=> light.device.properties[0].value === 'on')
  const noOfActiveLights = activelights.length

  /* Handle click sets widget color & selects widget */
  const handleClick = () => {
    if(activeWidget !== 'Lighting'){
      setactiveWidget('Lighting')
      setActiveWidgetColor('#F2BE07')
      setSelected(true)
    }else{
      setactiveWidget(null)
      setActiveWidgetColor(null)
      setSelected(false)
    }
  }

  return (
    <div 
      className={activeWidget === 'Lighting' && selected ? `card-widget ${styles.cardWidget__selected}`: 'card-widget'} 
      onClick={handleClick}>
      { 
        /* Selected widget Content */
        activeWidget === 'Lighting' && selected
        ?(
          <div className='card-widget__selected__content'>
            <p>Longest Light On Time is in <span className='zoneText'>Zone 6</span></p>
          </div>
        ):(
          <>
            <IconComponent name="lightbulb" width={24} height={24} color="#FFC700"/>
            {/* Card Widget Content  */}
            <div className='card-widget__content'>
              <h4 className='card-widget__title'>Lighting</h4>
              <div className={styles.cardWidget__data}>
                <div className='card-widget__valueContainer'>
                  <div className='card-widget__value'>{noOfActiveLights} <span>Zones On</span></div>
                </div>
                <div className='card-widget__valueContainer'>
                  <div className={styles.cardWidget__totalValue}>{total} <span>Total</span></div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

export default Lighting