import React, { useState } from "react";
import IconComponent from "../../common/icons/IconComponent";
import styles from "./lights.module.css";
import Dropdown from "../../common/dropdown/Dropdown";

const Lights = ({
  setOpenSidebar,
  setComponents,
  setSidebarColor,
  setCurrentSidebar,
  deviceOn,
  setDeviceOn,
  source,
  setSource,
  deviceDetail
}) => {
  const [selectedOption, setSelectedOption] = useState(source);
  const options = deviceDetail.digispace.properties.slice(-1)[0].value.map((dropdownValue,index) => dropdownValue.friendly_name);

  /* handleClick passes all the data for audio sidebar to render */
  const handleClick = () => {
    setOpenSidebar(true);
    setCurrentSidebar('Lights')
    setComponents({
      status: deviceOn ? "Device is On" : "Device is Off",
      statusIcon: deviceOn ? (
        <IconComponent
          name="ellipse"
          width={16}
          height={16}
          color={"#31BF8C"}
        />
      ) : (
        <IconComponent
          name="ellipse"
          width={16}
          height={16}
          color={"#F3C624"}
        />
      ),
      icon: "lightbulb",
      title: "Lighting Controller",
      dropdown: (
        <Dropdown
          options={options}
          selectedOption={source}
          setSelectedOption={setSource}
          deviceData={deviceDetail}
        />
      ),
      device: `Room Lights`,
      scene: source,
    });
    setSidebarColor( deviceOn ? "#F2BE07": '#BFBFBF');
  };
  return (
    <div className="card-widget-devices" onClick={handleClick}>
      {/* Device Heading */}
      <div className="card-widget-devices__heading">
        <IconComponent
          name="lightbulb"
          width={16}
          height={16}
          color={"#FFC700"}
        />
        <h4 className="card-widget-devices__title">{deviceDetail.asset_name}</h4>
      </div>
      {/* Device data */}
      <div className={styles.cardWidgetDevices__content}>
        <span className={styles.cardWidgetDevices__content__text}>
          Current scene is
        </span>
        <span className={styles.cardWidgetDevices__content__sourceText}>
          {selectedOption}
        </span>
      </div>
    </div>
  );
};

export default Lights;
