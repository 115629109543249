import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const RequiredAuthComponents = ({children}) => {
  const isAuth = localStorage.getItem("token") ? true : false;
  const location = useLocation();
  if (!isAuth) {
    return <Navigate to="/" state={{ from: location }} />;
  } else {
    return children;
  }
} 

export default RequiredAuthComponents
