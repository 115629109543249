import React from "react";
import IconComponent from "../../common/icons/IconComponent";
import styles from "./blinds.module.css";
import BlindControls from "../../blindControls/BlindControls";

const Blinds = ({
  setOpenSidebar,
  setComponents,
  setSidebarColor,
  setCurrentSidebar,
  deviceOn,
  setDeviceOn,
  deviceDetail
}) => {

  /* handleClick passes all the data for Blinds sidebar to render */
  const handleClick = () => {
    setOpenSidebar(true);
    setCurrentSidebar('Blinds')
    // sets all the sidebar components
    setComponents({
      status: deviceOn ? "Device is On" : "Device is Off",
      statusIcon: deviceOn ? (
        <IconComponent
          name="ellipse"
          width={16}
          height={16}
          color={"#31BF8C"}
        />
      ) : (
        <IconComponent
          name="ellipse"
          width={16}
          height={16}
          color={"#F3C624"}
        />
      ),
      icon: "blinds",
      title: "Blinds Controller",
      blindControls: <BlindControls deviceData={deviceDetail} setColor={setSidebarColor} color={"#EB719D"}/>,
      device: `Blinds`,
    });
    setSidebarColor(deviceOn ? "#EB719D" : "#BFBFBF");
  };

  return (
    <div className="card-widget-devices" onClick={handleClick}>
      {/* Device Heading */}
      <div className="card-widget-devices__heading">
        <IconComponent name="blinds" width={16} height={16} color={"#EB719D"} />
        <h4 className="card-widget-devices__title">{deviceDetail.asset_name}</h4>
      </div>

      {/* Device data */}
      <div className="card-widget__content">
        <span className={styles.cardWidgetDevices__content__text}>
          Tap for control
        </span>
      </div>
    </div>
  );
};

export default Blinds;
