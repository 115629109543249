import React, { useContext, useState } from 'react'
import IconComponent from '../../common/icons/IconComponent'
import styles from './temperature.module.css'
import { GlobalContext } from '../../../context/provider'

const Temperature = ({activeWidget,setactiveWidget,setActiveWidgetColor}) => {
  const [selected, setSelected] = useState(false)
  const { state, dispatch } = useContext(GlobalContext);

  const hvacs =  state.floorAssets.res.filter((asset)=> asset.device.subtype === 'hvac')
  
  /* Calculates Minimum Temperature */
  const minTemperature = hvacs.reduce((prev,current) =>{

    if(current.device.properties[1].value){
      return prev < current?.device.properties[1].value
      ? prev
      : current.device.properties[1].value
    }
  },Infinity)

  /* Calculates Maximum Temperature */
  const maxTemperature = hvacs.reduce((prev,current) => {

    if(current.device.properties[1].value){
      return prev > current.device.properties[1].value
      ? prev
      : current.device.properties[1].value
    }
  }
  ,0)
  // hvacs.filter((havac) => havac.device.properties[1].value)
  /* Handle click sets widget color & selects widget */

  const handleClick = () => {
    if(activeWidget !== 'Temperature'){
      setactiveWidget('Temperature')
      setActiveWidgetColor('#FE5D70')
      setSelected(true)
    }else{
      setactiveWidget(null)
      setActiveWidgetColor(null)
      setSelected(false)
    }
  }

  return (
    <div 
      className={selected && activeWidget === 'Temperature' ? `card-widget ${styles.cardWidget__selected}`: 'card-widget'} 
      onClick={handleClick}
    >
      {
        /* Selected widget Content */
        selected && activeWidget === 'Temperature' 
        ?(
          <div className='card-widget__selected__content'>
            <p> Highest temperature is in <span className='zoneText'>Zone 2</span>  and the lowest is in <span className='zoneText'>Zone 5</span> </p>
          </div>
        ):(
          <>
            <IconComponent name="thermometer" width={24} height={24} color="#FE5D70"/>
            {/* Card Widget Content  */}
            <div className='card-widget__content'>
              <h4 className='card-widget__title'>Temperature</h4>
              <div className='card-widget__data'>
                <div className='card-widget__valueContainer'>
                  <IconComponent name="thermometer-warm" width={16} height={16} color={"rgba(24, 24, 24, 0.6)"} />
                  <div className='card-widget__value'>{minTemperature ? minTemperature: 0}&deg;C</div>
                </div>
                <div className='card-widget__valueContainer'>
                  <IconComponent name="thermometer-cold" width={16} height={16} color={"rgba(24, 24, 24, 0.6)"} />
                  <div className='card-widget__value'>{maxTemperature ? maxTemperature: 0}&deg;C</div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

export default Temperature