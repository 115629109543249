import React from 'react'
import { Route, Routes} from 'react-router-dom'
import Login from '../pages/loginPage/Login'
import Floor from '../pages/floorPage/Floor';
import Zone from '../pages/zonePage/Zone'
import RequiredAuthComponents from '../Hoc/RequiredAuthComponents';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/floor" element={<RequiredAuthComponents><Floor/></RequiredAuthComponents>}/>
      <Route path="/zone" element={<RequiredAuthComponents><Zone/></RequiredAuthComponents>}/>
    </Routes>
  )
}

export default MainRoutes
