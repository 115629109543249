import React from "react";

// icon components
const IconComponent = ({ name, width, height, color }) => {
  // icons list objects
  const icons = {
    digiSpace_Logo_BLK: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 120 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_13_922)">
          <path
            d="M21.6177 15.8219V0.22052H26.9705C31.7854 0.22052 34.6191 3.08382 34.6191 8.02122C34.6191 12.9221 31.8222 15.8219 27.1377 15.8219H21.6177ZM24.8954 3.47012V12.5557H27.1369C29.6736 12.5557 31.3029 10.7933 31.3029 8.02205C31.3029 5.17699 29.6736 3.47012 26.9696 3.47012H24.8954Z"
            fill="white"
          />
          <path
            d="M37.8977 0C38.9908 0 39.6942 0.71541 39.6942 1.79889C39.6942 2.88236 38.9908 3.59777 37.8977 3.59777C36.787 3.59777 36.0643 2.88153 36.0643 1.79889C36.0643 0.716239 36.787 0 37.8977 0ZM36.25 15.8219V4.80808H39.5461V15.8219H36.25Z"
            fill="white"
          />
          <path
            d="M49.3057 14.794C48.8984 15.4182 47.6389 15.9321 46.3985 15.9321C43.084 15.9321 41.1018 13.6939 41.1018 10.2951C41.1018 6.91699 43.1024 4.67792 46.3985 4.67792C47.6205 4.67792 48.8616 5.15541 49.3057 5.83434V4.80807H52.5835V15.235C52.5835 18.6338 50.3989 20.872 47.1019 20.872C44.2131 20.872 42.2133 19.1652 41.7316 16.5771H45.195C45.5655 17.1822 46.1986 17.6233 47.1019 17.6233C48.4351 17.6233 49.3057 16.6873 49.3057 15.2375V14.794ZM46.8426 12.6651C48.3247 12.6651 49.3241 11.6919 49.3057 10.3158C49.3057 8.93887 48.287 7.92918 46.8426 7.92918C45.3246 7.92918 44.3795 8.86509 44.3795 10.2976C44.3795 11.7466 45.3062 12.6651 46.8426 12.6651Z"
            fill="white"
          />
          <path
            d="M55.9741 0C57.0664 0 57.7707 0.71541 57.7707 1.79889C57.7707 2.88236 57.0664 3.59777 55.9741 3.59777C54.8626 3.59777 54.1408 2.88153 54.1408 1.79889C54.1408 0.716239 54.8659 0 55.9741 0ZM54.3256 15.8219V4.80808H57.626V15.8219H54.3256Z"
            fill="white"
          />
          <path
            d="M60.938 11.2335C61.1053 13.0871 62.8081 14.3538 64.9007 14.3538C67.0485 14.3538 68.5858 13.1236 68.6226 11.5452C68.6418 9.50757 66.7525 8.93889 64.9191 8.47798C62.4937 7.89023 59.7713 7.52299 59.7713 4.18221C59.8081 1.42916 62.0855 0.126007 64.8639 0.126007C67.5487 0.126007 69.827 1.6132 69.919 4.32976H68.2529C68.1233 2.65854 66.6638 1.70438 64.8639 1.70438C62.9562 1.70438 61.475 2.56735 61.4348 4.16397C61.398 6.14689 63.1912 6.44035 65.1943 6.91701C67.583 7.44922 70.3238 8.36772 70.287 11.5245C70.2552 13.9501 68.0488 15.9164 64.9191 15.9322C61.9191 15.9322 59.4192 14.1515 59.2711 11.2335H60.938Z"
            fill="white"
          />
          <path
            d="M73.5321 7.12178C74.0506 5.65365 75.7175 4.69867 77.4948 4.69867C80.6805 4.69867 82.8099 6.93691 82.8099 10.3357C82.8099 13.7138 80.6805 15.9529 77.4948 15.9529C75.7543 15.9529 74.0506 14.9581 73.5321 13.5116V20.4319H71.8652V4.80809H73.5321V7.12178ZM77.3283 6.35083C75.0877 6.35083 73.5321 7.92589 73.5321 10.3158C73.5321 12.6652 75.1212 14.2949 77.3283 14.2949C79.569 14.2949 81.143 12.7199 81.143 10.3299C81.143 7.9292 79.5506 6.35083 77.3283 6.35083Z"
            fill="white"
          />
          <path
            d="M93.533 13.5124C93.0153 14.9623 91.3484 15.9355 89.5703 15.9355C86.3854 15.9355 84.256 13.6773 84.256 10.2984C84.256 6.89959 86.3854 4.68124 89.5703 4.68124C91.3116 4.68124 93.0153 5.65447 93.533 7.12259V4.80808H95.1999V15.8219H93.533V13.5124ZM89.7376 14.2833C91.9782 14.2833 93.533 12.7083 93.533 10.3191C93.533 7.95074 91.9439 6.34003 89.7376 6.34003C87.4961 6.34003 85.9229 7.91509 85.9229 10.305C85.9229 12.695 87.5153 14.28 89.7376 14.28V14.2833Z"
            fill="white"
          />
          <path
            d="M98.7754 10.2793C98.7754 12.6834 100.293 14.2626 102.479 14.2626C104.164 14.2626 105.479 13.3084 105.997 11.6563H107.754C107.143 14.3173 105.161 15.9147 102.476 15.9147C99.2906 15.9147 97.106 13.6765 97.106 10.2777C97.106 6.90042 99.2906 4.66135 102.476 4.66135C105.161 4.66135 107.143 6.23641 107.754 8.90158H105.997C105.441 7.23118 104.164 6.3135 102.479 6.3135C100.294 6.31433 98.7754 7.8753 98.7754 10.2793Z"
            fill="white"
          />
          <path
            d="M119.592 12.4446C118.832 14.5005 116.889 15.9139 114.685 15.9139C111.518 15.9139 109.296 13.5828 109.296 10.2769C109.296 7.08363 111.611 4.66052 114.649 4.66052C117.648 4.66052 120.002 7.06456 120.002 10.1666C120.001 10.4064 119.983 10.6458 119.946 10.8828H111C111.24 12.9752 112.685 14.3521 114.666 14.3521C116.129 14.3521 117.278 13.5629 117.815 12.4455L119.592 12.4446ZM118.295 9.65513C118.074 7.67221 116.573 6.22232 114.648 6.22232C112.723 6.22232 111.259 7.63159 111 9.65513H118.295Z"
            fill="white"
          />
          <path
            d="M7.95388 2.51594C9.28379 2.51596 10.5726 2.97304 11.6006 3.80929C12.6286 4.64554 13.3323 5.80922 13.5917 7.10206C13.8512 8.3949 13.6503 9.7369 13.0234 10.8994C12.3964 12.0619 11.3822 12.973 10.1535 13.4774C8.92483 13.9818 7.55769 14.0484 6.28505 13.6657C5.0124 13.2831 3.91299 12.4749 3.17414 11.3789C2.4353 10.2828 2.10272 8.96681 2.23309 7.65499C2.36346 6.34318 2.94871 5.11675 3.88911 4.18468C4.96763 3.11688 6.42947 2.51674 7.95388 2.51594ZM7.95388 2.01855C6.71757 2.01872 5.50908 2.38223 4.48122 3.06314C3.45335 3.74404 2.65227 4.71175 2.17927 5.8439C1.70627 6.97604 1.5826 8.22179 1.82389 9.4236C2.06518 10.6254 2.6606 11.7293 3.53486 12.5957C4.40912 13.4621 5.52294 14.0522 6.7355 14.2912C7.94806 14.5302 9.2049 14.4074 10.3471 13.9384C11.4893 13.4695 12.4655 12.6753 13.1523 11.6565C13.8392 10.6376 14.2058 9.43973 14.2058 8.21435C14.2058 7.40064 14.044 6.59489 13.7298 5.84313C13.4156 5.09137 12.9551 4.40831 12.3746 3.83297C11.794 3.25762 11.1048 2.80126 10.3463 2.48994C9.5878 2.17862 8.77485 2.01845 7.95388 2.01855Z"
            fill="white"
          />
          <path
            d="M3.21752 6.7164C4.99451 6.7164 6.43504 5.28859 6.43504 3.52731C6.43504 1.76603 4.99451 0.338226 3.21752 0.338226C1.44053 0.338226 0 1.76603 0 3.52731C0 5.28859 1.44053 6.7164 3.21752 6.7164Z"
            fill="white"
          />
          <path
            d="M12.6417 6.7164C14.4187 6.7164 15.8593 5.28859 15.8593 3.52731C15.8593 1.76603 14.4187 0.338226 12.6417 0.338226C10.8648 0.338226 9.42422 1.76603 9.42422 3.52731C9.42422 5.28859 10.8648 6.7164 12.6417 6.7164Z"
            fill="white"
          />
          <path
            d="M3.21752 16.0573C4.99451 16.0573 6.43504 14.6295 6.43504 12.8683C6.43504 11.107 4.99451 9.67917 3.21752 9.67917C1.44053 9.67917 0 11.107 0 12.8683C0 14.6295 1.44053 16.0573 3.21752 16.0573Z"
            fill="white"
          />
          <path
            d="M12.6417 16.0573C14.4187 16.0573 15.8593 14.6295 15.8593 12.8683C15.8593 11.107 14.4187 9.67917 12.6417 9.67917C10.8648 9.67917 9.42422 11.107 9.42422 12.8683C9.42422 14.6295 10.8648 16.0573 12.6417 16.0573Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_13_922">
            <rect width="120" height="20.8696" fill={color} />
          </clipPath>
        </defs>
      </svg>
    ),
    "arrow-up": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071C4.68342 13.0976 5.31658 13.0976 5.70711 12.7071L11 7.41421L11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19L13 7.41421L18.2929 12.7071C18.6834 13.0976 19.3166 13.0976 19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L12.7071 4.29289Z"
          fill={color}
        />
      </svg>
    ),
    "arrow-down": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V16.5858L5.70711 11.2929C5.31658 10.9024 4.68342 10.9024 4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929C19.3166 10.9024 18.6834 10.9024 18.2929 11.2929L13 16.5858V5Z"
          fill={color}
        />
      </svg>
    ),
    "arrow-circle-broken-up-right": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25903 14.3094C3.96377 14.1341 3.81615 14.0464 3.72848 13.8816C3.65852 13.75 3.6341 13.5552 3.66941 13.4105C3.71367 13.2291 3.85323 13.0896 4.13236 12.8104L9.21894 7.72386V9.88562C9.21894 10.2538 9.51742 10.5523 9.88561 10.5523C10.2538 10.5523 10.5523 10.2538 10.5523 9.88562V6.11439C10.5523 5.7462 10.2538 5.44772 9.88561 5.44772L6.11437 5.44772C5.74618 5.44772 5.4477 5.7462 5.44771 6.11439C5.4477 6.48258 5.74618 6.78105 6.11437 6.78105L8.27613 6.78105L3.18955 11.8676C2.91042 12.1468 2.77086 12.2863 2.58949 12.3306C2.44475 12.3659 2.24996 12.3415 2.11842 12.2715C1.95359 12.1839 1.86592 12.0362 1.69057 11.741C0.0193041 8.92689 0.393962 5.23513 2.81454 2.81456C5.67838 -0.0492891 10.3216 -0.0492896 13.1854 2.81456C16.0493 5.6784 16.0493 10.3216 13.1854 13.1855C10.7649 15.606 7.07311 15.9807 4.25903 14.3094Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    headphones: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.666672 7.66667C0.666672 3.61658 3.94992 0.333336 8 0.333336C12.0501 0.333336 15.3333 3.61658 15.3333 7.66667V10.3333C15.3333 10.7015 15.0349 11 14.6667 11C14.2985 11 14 10.7015 14 10.3333V7.66667C14 4.35296 11.3137 1.66667 8 1.66667C4.6863 1.66667 2.00001 4.35296 2.00001 7.66667V10.3333C2.00001 10.7015 1.70153 11 1.33334 11C0.965149 11 0.666672 10.7015 0.666672 10.3333V7.66667Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.66667 9.33334C2.66667 8.04467 3.71134 7 5 7C6.28867 7 7.33334 8.04467 7.33334 9.33334V11.3333C7.33334 12.622 6.28867 13.6667 5 13.6667C3.71134 13.6667 2.66667 12.622 2.66667 11.3333V9.33334Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 7C9.71134 7 8.66667 8.04467 8.66667 9.33334V11.3333C8.66667 12.622 9.71134 13.6667 11 13.6667C12.2887 13.6667 13.3333 12.622 13.3333 11.3333V9.33334C13.3333 8.04467 12.2887 7 11 7Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "log-in-03-alt": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7206 0.480425C16.3436 0.451115 16.9236 0.489178 17.4779 0.709607C18.3304 1.04855 19.0404 1.67019 19.489 2.47034C19.7808 2.99072 19.8952 3.56062 19.9485 4.18194C20 4.78354 20 5.5294 20 6.43818V15.562C20 16.4708 20 17.2165 19.9485 17.8181C19.8952 18.4394 19.7808 19.0093 19.489 19.5297C19.0404 20.3298 18.3304 20.9515 17.4779 21.2904C16.9236 21.5108 16.3436 21.5489 15.7206 21.5196C15.1175 21.4912 14.3783 21.3926 13.4774 21.2725L6.84365 20.388L6.84146 20.3877L6.79049 20.3809C6.49183 20.3412 6.26957 20.3116 6.06943 20.2637C4.41682 19.8684 3.19434 18.4721 3.02091 16.7817C2.9999 16.577 2.99994 16.3527 3.00001 16.0513V15.6C3.00001 15.0399 3.00001 14.7599 3.109 14.546C3.20487 14.3578 3.35785 14.2049 3.54602 14.109C3.75993 14 4.03995 14 4.60001 14H6.0404C6.41102 14 6.59632 14 6.70126 14.052C6.81675 14.1093 6.85725 14.1483 6.91871 14.2616C6.97455 14.3646 6.98372 14.6134 7.00205 15.1111C7.02894 15.8414 7.32115 16.5638 7.87868 17.1213C9.05025 18.2929 10.9497 18.2929 12.1213 17.1213L16.1213 13.1213C17.2929 11.9497 17.2929 10.0503 16.1213 8.87868L12.1213 4.87868C10.9497 3.70711 9.05025 3.70711 7.87868 4.87868C7.32115 5.4362 7.02894 6.15858 7.00205 6.8889C6.98372 7.3866 6.97455 7.63545 6.91871 7.7384C6.85725 7.8517 6.81675 7.89074 6.70126 7.94798C6.59632 8 6.41102 8 6.0404 8H4.60001C4.03995 8 3.75993 8 3.54602 7.89101C3.35785 7.79513 3.20487 7.64215 3.109 7.45399C3.00001 7.24008 3.00001 6.96005 3.00001 6.4V5.94871C2.99994 5.64731 2.9999 5.42304 3.0209 5.21828C3.19434 3.52793 4.41682 2.13163 6.06943 1.73631C6.26961 1.68843 6.49192 1.65883 6.79069 1.61906L13.4774 0.727505C14.3783 0.607375 15.1175 0.508805 15.7206 0.480425Z"
          fill={color}
          fill-opacity="0.87"
        />
        <path
          d="M9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289L14.7071 10.2929C15.0976 10.6834 15.0976 11.3166 14.7071 11.7071L10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071C8.90237 15.3166 8.90237 14.6834 9.29289 14.2929L11.5858 12H1C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10H11.5858L9.29289 7.70711C8.90237 7.31658 8.90237 6.68342 9.29289 6.29289Z"
          fill={color}
          fill-opacity="0.87"
        />
      </svg>
    ),
    "mail-01": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.42235 3.45429C1.2224 3.31147 1.12242 3.24005 1.03526 3.23078C0.904907 3.21692 0.774267 3.28415 0.709785 3.39828C0.666666 3.47459 0.666668 3.5961 0.666672 3.83912V8.16087C0.666664 8.69751 0.666657 9.14042 0.696135 9.50122C0.726752 9.87595 0.792461 10.2204 0.957322 10.544C1.21298 11.0457 1.62093 11.4537 2.1227 11.7094C2.44626 11.8742 2.79072 11.9399 3.16546 11.9705C3.52625 12 3.96915 12 4.50579 12H11.4942C12.0309 12 12.4738 12 12.8346 11.9705C13.2093 11.9399 13.5538 11.8742 13.8773 11.7094C14.3791 11.4537 14.787 11.0457 15.0427 10.544C15.2076 10.2204 15.2733 9.87595 15.3039 9.50122C15.3334 9.14042 15.3333 8.69752 15.3333 8.16088V3.83912C15.3333 3.72001 15.3333 3.66045 15.3224 3.61886C15.2731 3.43084 15.0687 3.32374 14.886 3.39021C14.8456 3.40491 14.796 3.43924 14.6969 3.50789L9.53544 7.08129C9.16948 7.33544 8.84817 7.55859 8.48313 7.64707C8.16385 7.72446 7.83042 7.72201 7.51232 7.63993C7.14862 7.54609 6.83062 7.31824 6.46844 7.05874L1.42235 3.45429Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          d="M14.7552 1.84581C14.8783 1.76061 14.9398 1.71801 14.9776 1.64949C15.007 1.59629 15.0237 1.51203 15.017 1.45163C15.0084 1.37386 14.9753 1.32251 14.9092 1.21982C14.656 0.826428 14.2915 0.501704 13.8773 0.29065C13.5538 0.12579 13.2093 0.0600801 12.8346 0.0294633C12.4738 -1.47695e-05 12.0309 -7.96811e-06 11.4942 2.74431e-07H4.50581C3.96916 -7.96811e-06 3.52625 -1.4772e-05 3.16546 0.0294633C2.79072 0.0600801 2.44626 0.12579 2.1227 0.29065C1.75001 0.480544 1.42787 0.755068 1.18291 1.0889C1.10585 1.19393 1.06732 1.24644 1.05462 1.32614C1.04469 1.38846 1.05856 1.47505 1.08745 1.53116C1.12439 1.60291 1.18847 1.64868 1.31662 1.74022L7.1667 5.91884C7.65151 6.26514 7.75439 6.32539 7.84543 6.34888C7.95147 6.37624 8.06261 6.37706 8.16904 6.35126C8.26042 6.32911 8.36417 6.27038 8.85402 5.93125L14.7552 1.84581Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    passcode: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.44098 5.05548e-07H12.559C12.9104 -1.04545e-05 13.2137 -1.99104e-05 13.4634 0.0203817C13.7269 0.0419157 13.9891 0.0894597 14.2413 0.217989C14.6176 0.409736 14.9236 0.715697 15.1154 1.09202C15.2439 1.34427 15.2914 1.60642 15.313 1.86998C15.3334 2.11969 15.3334 2.42287 15.3333 2.77429V5.22571C15.3334 5.57714 15.3334 5.88031 15.313 6.13002C15.2914 6.39359 15.2439 6.65573 15.1154 6.90798C14.9236 7.28431 14.6176 7.59027 14.2413 7.78202C13.9891 7.91055 13.7269 7.95809 13.4634 7.97962C13.2136 8.00003 12.9105 8.00002 12.559 8H3.44097C3.08954 8.00002 2.78637 8.00003 2.53666 7.97962C2.27309 7.95809 2.01095 7.91055 1.75869 7.78202C1.38237 7.59027 1.07641 7.28431 0.884661 6.90798C0.756132 6.65573 0.708588 6.39359 0.687054 6.13002C0.666652 5.88032 0.666661 5.57713 0.666672 5.22572V2.77428C0.666661 2.42287 0.666652 2.11969 0.687054 1.86998C0.708588 1.60642 0.756132 1.34427 0.884661 1.09202C1.07641 0.715697 1.38237 0.409736 1.75869 0.217989C2.01095 0.0894597 2.27309 0.0419157 2.53666 0.0203817C2.78636 -1.99104e-05 3.08956 -1.04545e-05 3.44098 5.05548e-07ZM4.66667 3.16667C4.20644 3.16667 3.83334 3.53976 3.83334 4C3.83334 4.46024 4.20644 4.83333 4.66667 4.83333C5.12691 4.83333 5.50001 4.46024 5.50001 4C5.50001 3.53976 5.12691 3.16667 4.66667 3.16667ZM7.16667 4C7.16667 3.53976 7.53977 3.16667 8.00001 3.16667C8.46024 3.16667 8.83334 3.53976 8.83334 4C8.83334 4.46024 8.46024 4.83333 8.00001 4.83333C7.53977 4.83333 7.16667 4.46024 7.16667 4ZM10.5 4C10.5 3.53976 10.8731 3.16667 11.3333 3.16667C11.7936 3.16667 12.1667 3.53976 12.1667 4C12.1667 4.46024 11.7936 4.83333 11.3333 4.83333C10.8731 4.83333 10.5 4.46024 10.5 4Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "log-in-04-alt": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.893267 9.81618C0.808481 9.48341 0.766088 9.31702 0.820653 9.13848C0.864197 8.996 0.984661 8.841 1.11198 8.76362C1.27152 8.66667 1.46889 8.66667 1.86364 8.66667L9.05715 8.66667L7.52856 10.1953C7.26821 10.4556 7.26821 10.8777 7.52856 11.1381C7.78891 11.3984 8.21102 11.3984 8.47137 11.1381L11.138 8.4714C11.3984 8.21105 11.3984 7.78894 11.138 7.5286L8.47137 4.86193C8.21102 4.60158 7.78891 4.60158 7.52856 4.86193C7.26821 5.12228 7.26821 5.54439 7.52856 5.80474L9.05715 7.33333L1.86363 7.33333C1.46889 7.33333 1.27152 7.33333 1.11198 7.23638C0.984661 7.159 0.864197 7.004 0.820653 6.86152C0.766088 6.68298 0.808481 6.51659 0.893267 6.18382C1.70136 3.01221 4.57675 0.666667 7.99996 0.666667C12.0501 0.666667 15.3333 3.94991 15.3333 8C15.3333 12.0501 12.0501 15.3333 7.99996 15.3333C4.57675 15.3333 1.70136 12.9878 0.893267 9.81618Z"
          fill={color}
        />
      </svg>
    ),
    "chart-breakout-square": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8923 0.333344H3.10762C2.7562 0.333333 2.453 0.333323 2.2033 0.353725C1.93973 0.375259 1.67759 0.422803 1.42533 0.551332C1.04901 0.743079 0.743048 1.04904 0.551302 1.42536C0.422772 1.67762 0.375228 1.93976 0.353694 2.20333C0.333292 2.45304 0.333302 2.7562 0.333314 3.10762V6.92746C0.333314 7.21626 0.333314 7.36066 0.392864 7.49193C0.44108 7.59821 0.538453 7.71155 0.636265 7.77522C0.757068 7.85386 0.881703 7.87285 1.13097 7.91083C1.52861 7.97141 1.93134 7.99998 2.33329 7.99998C4.53707 7.99998 6.53286 7.10931 7.98036 5.66668H6.99998C6.63179 5.66668 6.33331 5.3682 6.33331 5.00001C6.33331 4.63182 6.63179 4.33334 6.99998 4.33334H9.66665C10.0348 4.33334 10.3333 4.63182 10.3333 5.00001V7.66668C10.3333 8.03487 10.0348 8.33334 9.66665 8.33334C9.29846 8.33334 8.99998 8.03487 8.99998 7.66668V6.53187C7.30655 8.25992 4.94529 9.33332 2.33329 9.33332C2.10129 9.33332 1.87119 9.32484 1.64329 9.30817C1.13527 9.27101 0.881268 9.25243 0.728064 9.32087C0.581568 9.38631 0.488704 9.47263 0.412748 9.61396C0.333314 9.76176 0.333314 9.98889 0.333314 10.4432V10.8924C0.333302 11.2438 0.333292 11.547 0.353694 11.7967C0.375228 12.0603 0.422772 12.3224 0.551302 12.5747C0.743048 12.951 1.04901 13.2569 1.42533 13.4487C1.67759 13.5772 1.93973 13.6248 2.2033 13.6463C2.45301 13.6667 2.75618 13.6667 3.1076 13.6667H10.8924C11.2438 13.6667 11.547 13.6667 11.7967 13.6463C12.0602 13.6248 12.3224 13.5772 12.5746 13.4487C12.951 13.2569 13.2569 12.951 13.4487 12.5747C13.5772 12.3224 13.6247 12.0603 13.6463 11.7967C13.6667 11.547 13.6667 11.2438 13.6666 10.8924V3.10763C13.6667 2.75621 13.6667 2.45304 13.6463 2.20333C13.6247 1.93976 13.5772 1.67762 13.4487 1.42536C13.2569 1.04904 12.951 0.743079 12.5746 0.551332C12.3224 0.422803 12.0602 0.375259 11.7967 0.353725C11.547 0.333323 11.2438 0.333333 10.8923 0.333344Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "chevron-left": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0892 4.41073C13.4147 4.73617 13.4147 5.26381 13.0892 5.58925L8.6785 9.99999L13.0892 14.4107C13.4147 14.7362 13.4147 15.2638 13.0892 15.5892C12.7638 15.9147 12.2362 15.9147 11.9107 15.5892L6.91073 10.5892C6.5853 10.2638 6.5853 9.73617 6.91073 9.41073L11.9107 4.41073C12.2362 4.0853 12.7638 4.0853 13.0892 4.41073Z"
          fill={color}
          fill-opacity="0.38"
        />
      </svg>
    ),
    "chevron-right": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.91073 4.41073C7.23617 4.0853 7.76381 4.0853 8.08925 4.41073L13.0892 9.41073C13.4147 9.73617 13.4147 10.2638 13.0892 10.5892L8.08925 15.5892C7.76381 15.9147 7.23617 15.9147 6.91073 15.5892C6.5853 15.2638 6.5853 14.7362 6.91073 14.4107L11.3215 9.99999L6.91073 5.58925C6.5853 5.26381 6.5853 4.73617 6.91073 4.41073Z"
          fill={color}
          fill-opacity="0.38"
        />
      </svg>
    ),
    "chevron-down": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.5286 5.52861C3.78895 5.26826 4.21106 5.26826 4.47141 5.52861L8 9.0572L11.5286 5.52861C11.7889 5.26826 12.2111 5.26826 12.4714 5.52861C12.7318 5.78896 12.7318 6.21107 12.4714 6.47141L8.47141 10.4714C8.21106 10.7318 7.78895 10.7318 7.5286 10.4714L3.5286 6.47141C3.26825 6.21107 3.26825 5.78896 3.5286 5.52861Z"
          fill={color}
        />
      </svg>
    ),
    wind: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 4.5C17.4078 4.5 16.8767 4.75615 16.5091 5.16691C16.1407 5.57844 15.5085 5.61346 15.097 5.24513C14.6855 4.87681 14.6505 4.24461 15.0188 3.83309C15.7498 3.01635 16.8153 2.5 18 2.5C20.2092 2.5 22 4.29086 22 6.5C22 8.70914 20.2092 10.5 18 10.5H12C11.4477 10.5 11 10.0523 11 9.5C11 8.94772 11.4477 8.5 12 8.5H18C19.1046 8.5 20 7.60457 20 6.5C20 5.39543 19.1046 4.5 18 4.5Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 2C7.40776 2 6.87667 2.25615 6.50903 2.66691C6.1407 3.07844 5.50851 3.11346 5.09698 2.74513C4.68545 2.37681 4.65043 1.74461 5.01876 1.33309C5.74976 0.516351 6.81524 0 8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H8C9.10457 6 10 5.10457 10 4C10 2.89543 9.10457 2 8 2Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 18C11.4078 18 10.8767 17.7438 10.509 17.3331C10.1407 16.9216 9.50851 16.8865 9.09698 17.2549C8.68546 17.6232 8.65043 18.2554 9.01876 18.6669C9.74976 19.4836 10.8152 20 12 20C14.2091 20 16 18.2091 16 16C16 13.7909 14.2091 12 12 12H1C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H12C13.1046 14 14 14.8954 14 16C14 17.1046 13.1046 18 12 18Z"
          fill={color}
        />
      </svg>
    ),
    "arrow-circle-broken-up": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.81616 15.1067C9.4834 15.1914 9.31701 15.2338 9.13847 15.1793C8.99599 15.1357 8.84099 15.0153 8.76361 14.8879C8.66666 14.7284 8.66666 14.531 8.66666 14.1363V6.94277L10.1953 8.47136C10.4556 8.73171 10.8777 8.73171 11.1381 8.47136C11.3984 8.21101 11.3984 7.7889 11.1381 7.52855L8.47139 4.86189C8.21104 4.60154 7.78893 4.60154 7.52859 4.86189L4.86192 7.52855C4.60157 7.7889 4.60157 8.21101 4.86192 8.47136C5.12227 8.73171 5.54438 8.73171 5.80473 8.47136L7.33332 6.94277V14.1363C7.33332 14.531 7.33332 14.7284 7.23637 14.8879C7.15899 15.0153 7.00399 15.1357 6.8615 15.1793C6.68297 15.2338 6.51658 15.1914 6.18381 15.1067C3.01219 14.2986 0.666656 11.4232 0.666656 7.99996C0.666657 3.94987 3.9499 0.666626 7.99999 0.666626C12.0501 0.666626 15.3333 3.94987 15.3333 7.99996C15.3333 11.4232 12.9878 14.2986 9.81616 15.1067Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "arrow-circle-broken-down": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6.18378 0.893297C6.51655 0.808512 6.68294 0.766119 6.86147 0.820683C7.00396 0.864228 7.15896 0.984691 7.23634 1.11201C7.33329 1.27155 7.33329 1.46892 7.33329 1.86367L7.33329 9.05718L5.8047 7.52859C5.54435 7.26824 5.12224 7.26824 4.86189 7.52859C4.60154 7.78894 4.60154 8.21105 4.86189 8.4714L7.52855 11.1381C7.7889 11.3984 8.21101 11.3984 8.47136 11.1381L11.138 8.4714C11.3984 8.21105 11.3984 7.78894 11.138 7.52859C10.8777 7.26824 10.4556 7.26824 10.1952 7.52859L8.66663 9.05718L8.66663 1.86367C8.66663 1.46892 8.66663 1.27155 8.76358 1.11201C8.84096 0.984691 8.99596 0.864228 9.13844 0.820683C9.31698 0.766119 9.48337 0.808512 9.81613 0.893297C12.9878 1.70139 15.3333 4.57678 15.3333 7.99999C15.3333 12.0501 12.05 15.3333 7.99996 15.3333C3.94987 15.3333 0.666626 12.0501 0.666626 7.99999C0.666626 4.57678 3.01216 1.70139 6.18378 0.893297Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    thermometer: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.8 0C3.86701 0 2.3 1.567 2.3 3.5V12.2572C1.07998 13.2649 0.300003 14.7912 0.300003 16.5C0.300003 19.5376 2.76244 22 5.8 22C8.83757 22 11.3 19.5376 11.3 16.5C11.3 14.7912 10.52 13.2649 9.3 12.2572V3.5C9.3 1.567 7.733 0 5.8 0ZM5.8 14.5C4.69543 14.5 3.8 15.3954 3.8 16.5C3.8 17.6046 4.69543 18.5 5.8 18.5C6.90457 18.5 7.8 17.6046 7.8 16.5C7.8 15.3954 6.90457 14.5 5.8 14.5Z"
          fill={color}
        />
      </svg>
    ),
    "thermometer-warm": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.74762 6.00671C6.34096 5.55841 7.06718 5.3214 7.81075 5.33338C8.17889 5.33931 8.47252 5.64256 8.46659 6.0107C8.46066 6.37884 8.15741 6.67247 7.78927 6.66654C7.34313 6.65935 6.9074 6.80156 6.55139 7.07054C6.19538 7.33952 5.93954 7.71982 5.82457 8.15095C5.7096 8.58209 5.74211 9.03928 5.91691 9.44981C6.09172 9.86034 6.39879 10.2006 6.78927 10.4165C7.11148 10.5947 7.22826 11.0003 7.05009 11.3226C6.87193 11.6448 6.4663 11.7615 6.14408 11.5834C5.49328 11.2235 4.9815 10.6564 4.69016 9.97217C4.39882 9.28795 4.34465 8.52596 4.53626 7.8074C4.72787 7.08885 5.15427 6.45501 5.74762 6.00671Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.80001 1.33329C8.1682 1.33329 8.46667 1.63177 8.46667 1.99996V3.33329C8.46667 3.70148 8.1682 3.99996 7.80001 3.99996C7.43182 3.99996 7.13334 3.70148 7.13334 3.33329V1.99996C7.13334 1.63177 7.43182 1.33329 7.80001 1.33329Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.67141 11.7952C4.93176 12.0556 4.93176 12.4777 4.67141 12.738L3.73808 13.6714C3.47773 13.9317 3.05562 13.9317 2.79527 13.6714C2.53492 13.411 2.53492 12.9889 2.79527 12.7286L3.7286 11.7952C3.98895 11.5349 4.41106 11.5349 4.67141 11.7952Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.466675 8.66663C0.466675 8.29844 0.765152 7.99996 1.13334 7.99996H2.46667C2.83486 7.99996 3.13334 8.29844 3.13334 8.66663C3.13334 9.03482 2.83486 9.33329 2.46667 9.33329H1.13334C0.765152 9.33329 0.466675 9.03482 0.466675 8.66663Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.79527 3.66189C3.05562 3.40154 3.47773 3.40154 3.73808 3.66189L4.67141 4.59522C4.93176 4.85557 4.93176 5.27768 4.67141 5.53803C4.41106 5.79838 3.98895 5.79838 3.7286 5.53803L2.79527 4.6047C2.53492 4.34435 2.53492 3.92224 2.79527 3.66189Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.80001 2.66663C9.80001 1.56206 10.6954 0.666626 11.8 0.666626C12.9046 0.666626 13.8 1.56206 13.8 2.66663V9.33314C14.6087 9.94055 15.1333 10.9087 15.1333 12C15.1333 13.8409 13.641 15.3333 11.8 15.3333C9.95906 15.3333 8.46667 13.8409 8.46667 12C8.46667 10.9087 8.99135 9.94055 9.80001 9.33314V2.66663Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "thermometer-cold": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8 0.666626C10.6954 0.666626 9.8 1.56206 9.8 2.66663V9.33314C8.99135 9.94055 8.46667 10.9087 8.46667 12C8.46667 13.8409 9.95905 15.3333 11.8 15.3333C13.641 15.3333 15.1333 13.8409 15.1333 12C15.1333 10.9087 14.6087 9.94055 13.8 9.33314V2.66663C13.8 1.56206 12.9046 0.666626 11.8 0.666626Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          d="M6.46667 2.66663C6.46667 2.29844 6.16819 1.99996 5.8 1.99996C5.43181 1.99996 5.13334 2.29844 5.13334 2.66663V4.39048L4.27141 3.52855C4.01106 3.26821 3.58895 3.26821 3.3286 3.52855C3.06825 3.7889 3.06825 4.21101 3.3286 4.47136L5.13334 6.2761V7.33329H4.07615L2.27141 5.52855C2.01106 5.26821 1.58895 5.26821 1.3286 5.52855C1.06825 5.7889 1.06825 6.21101 1.3286 6.47136L2.19053 7.33329H1.13334C0.765148 7.33329 0.466671 7.63177 0.466671 7.99996C0.466671 8.36815 0.765148 8.66663 1.13334 8.66663H2.19053L1.3286 9.52855C1.06825 9.7889 1.06825 10.211 1.3286 10.4714C1.58895 10.7317 2.01106 10.7317 2.27141 10.4714L4.07615 8.66663H5.13334V9.72382L3.3286 11.5286C3.06825 11.7889 3.06825 12.211 3.3286 12.4714C3.58895 12.7317 4.01106 12.7317 4.27141 12.4714L5.13334 11.6094V13.3333C5.13334 13.7015 5.43181 14 5.8 14C6.16819 14 6.46667 13.7015 6.46667 13.3333V11.5775C6.72162 11.7249 7.05329 11.6895 7.27141 11.4714C7.53176 11.211 7.53176 10.7889 7.27141 10.5286L6.46667 9.72382V8.66663H7.8C8.16819 8.66663 8.46667 8.36815 8.46667 7.99996C8.46667 7.63177 8.16819 7.33329 7.8 7.33329H6.46667V6.2761L8.27141 4.47136C8.53176 4.21101 8.53176 3.7889 8.27141 3.52855C8.01106 3.26821 7.58895 3.26821 7.3286 3.52855L6.46667 4.39048V2.66663Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    monitor: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.6 19C10.1621 19 8.80626 19.3252 7.60428 19.9017C7.10631 20.1405 6.509 19.9304 6.27017 19.4325C6.03133 18.9345 6.24139 18.3372 6.73936 18.0983C8.20697 17.3944 9.8579 17 11.6 17C13.3421 17 14.993 17.3944 16.4606 18.0983C16.9586 18.3372 17.1687 18.9345 16.9298 19.4325C16.691 19.9304 16.0937 20.1405 15.5957 19.9017C14.3938 19.3252 13.038 19 11.6 19Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.35871 4.31291e-07H16.8413C17.6463 -1.23241e-05 18.3106 -2.28137e-05 18.8518 0.0441945C19.4139 0.0901197 19.9306 0.188684 20.416 0.435975C21.1686 0.819468 21.7805 1.43139 22.164 2.18404C22.4113 2.66937 22.5099 3.18608 22.5558 3.74817C22.6 4.28936 22.6 4.95372 22.6 5.75868V10.2413C22.6 11.0463 22.6 11.7106 22.5558 12.2518C22.5099 12.8139 22.4113 13.3306 22.164 13.816C21.7805 14.5686 21.1686 15.1805 20.416 15.564C19.9306 15.8113 19.4139 15.9099 18.8518 15.9558C18.3106 16 17.6463 16 16.8413 16H6.35873C5.55375 16 4.88938 16 4.34818 15.9558C3.78608 15.9099 3.26938 15.8113 2.78404 15.564C2.0314 15.1805 1.41947 14.5686 1.03598 13.816C0.78869 13.3306 0.690126 12.8139 0.644201 12.2518C0.599983 11.7106 0.599994 11.0463 0.600007 10.2413V5.7587C0.599994 4.95373 0.599983 4.28937 0.644201 3.74818C0.690126 3.18608 0.78869 2.66937 1.03598 2.18404C1.41947 1.43139 2.0314 0.819468 2.78404 0.435975C3.26938 0.188684 3.78608 0.0901197 4.34818 0.0441945C4.88938 -2.28137e-05 5.55374 -1.23241e-05 6.35871 4.31291e-07Z"
          fill={color}
        />
      </svg>
    ),
    lightbulb: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.90002 21C4.90002 20.4477 5.34774 20 5.90002 20H10.9C11.4523 20 11.9 20.4477 11.9 21C11.9 21.5523 11.4523 22 10.9 22H5.90002C5.34774 22 4.90002 21.5523 4.90002 21Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.400024 8C0.400024 3.58172 3.98175 0 8.40002 0C12.8183 0 16.4 3.58172 16.4 8C16.4 10.9626 14.7896 13.5473 12.4 14.9295V15.032C12.4 15.4706 12.4 15.8491 12.3787 16.1624C12.3562 16.4922 12.3067 16.8221 12.1717 17.1481C11.8672 17.8831 11.2832 18.4672 10.5481 18.7716C10.2221 18.9066 9.89224 18.9561 9.56241 18.9787C9.24915 19 8.87066 19 8.43201 19H8.36803C7.92939 19 7.5509 19 7.23764 18.9787C6.90781 18.9561 6.5779 18.9066 6.25197 18.7716C5.51689 18.4672 4.93287 17.8831 4.62839 17.1481C4.49338 16.8221 4.44388 16.4922 4.42137 16.1624C4.4 15.8491 4.40001 15.4706 4.40002 15.032L4.40002 14.9295C2.01041 13.5473 0.400024 10.9626 0.400024 8Z"
          fill={color}
        />
      </svg>
    ),
    "lightbulb-03": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.3335 14.6666H7.66683M8.00016 10.2176C9.57679 9.46858 10.6668 7.86158 10.6668 5.99998C10.6668 3.42265 8.57749 1.33331 6.00016 1.33331C3.42283 1.33331 1.3335 3.42265 1.3335 5.99998C1.3335 7.86158 2.42353 9.46858 4.00016 10.2176V10.6666C4.00016 11.2879 4.00016 11.5985 4.10166 11.8436C4.23698 12.1703 4.49655 12.4298 4.82325 12.5652C5.06828 12.6666 5.37891 12.6666 6.00016 12.6666C6.62142 12.6666 6.93205 12.6666 7.17707 12.5652C7.50378 12.4298 7.76334 12.1703 7.89867 11.8436C8.00016 11.5985 8.00016 11.2879 8.00016 10.6666V10.2176Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    users: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.3065 13.388C16.5544 12.8945 17.1554 12.6954 17.6489 12.9433C19.2834 13.7644 20.665 15.0715 21.6649 16.6822C21.8163 16.9262 22.0369 17.2521 22.1053 17.6937C22.1786 18.1665 22.0503 18.6186 21.859 18.9624C21.6678 19.3061 21.3514 19.6536 20.911 19.8407C20.4924 20.0186 20.037 20 19.7 20C19.1478 20 18.7 19.5523 18.7 19C18.7 18.4477 19.1478 18 19.7 18C19.9152 18 20.0284 17.9994 20.1091 17.9939L20.1112 17.9902C20.1304 17.9558 19.9851 17.7683 19.9657 17.737C19.1434 16.4124 18.0285 15.3722 16.7512 14.7305C16.2576 14.4826 16.0585 13.8815 16.3065 13.388Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3045 1.02273C14.5503 0.528148 15.1505 0.326461 15.6451 0.572245C17.4536 1.47103 18.7 3.33904 18.7 5.5C18.7 7.66096 17.4536 9.52897 15.6451 10.4278C15.1505 10.6735 14.5503 10.4719 14.3045 9.97727C14.0587 9.48269 14.2604 8.88251 14.755 8.63673C15.9099 8.0628 16.7 6.87276 16.7 5.5C16.7 4.12724 15.9099 2.9372 14.755 2.36327C14.2604 2.11749 14.0587 1.51731 14.3045 1.02273Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.20004 5.5C3.20004 2.46243 5.66248 0 8.70004 0C11.7376 0 14.2 2.46243 14.2 5.5C14.2 8.53757 11.7376 11 8.70004 11C5.66248 11 3.20004 8.53757 3.20004 5.5Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.70004 12C11.8963 12 14.7536 13.8041 16.4972 16.4201C16.6508 16.6506 16.8146 16.8964 16.9291 17.13C17.067 17.4111 17.166 17.7341 17.1428 18.1198C17.1243 18.4275 17.0223 18.7129 16.901 18.9395C16.7796 19.1661 16.5986 19.4093 16.3528 19.5952C16.0235 19.8442 15.6686 19.9315 15.3549 19.968C15.0787 20.0001 14.7515 20.0001 14.422 20C10.6088 19.9993 6.79273 19.9993 2.97811 20C2.64857 20.0001 2.32142 20.0001 2.04519 19.968C1.73148 19.9315 1.3766 19.8442 1.04728 19.5952C0.801453 19.4093 0.620476 19.1661 0.499134 18.9395C0.377791 18.7129 0.275739 18.4275 0.257272 18.1198C0.234119 17.7341 0.333119 17.4111 0.470966 17.13C0.585494 16.8964 0.749303 16.6506 0.902915 16.4201C2.6465 13.8041 5.50381 12 8.70004 12Z"
          fill={color}
        />
      </svg>
    ),
    "users-03": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9999 9.55792C12.9705 10.0455 13.8026 10.828 14.4101 11.8064C14.5303 12.0002 14.5905 12.0971 14.6113 12.2312C14.6535 12.5038 14.4671 12.839 14.2132 12.9469C14.0882 13 13.9477 13 13.6666 13M10.6666 6.68816C11.6544 6.19726 12.3332 5.17791 12.3332 4C12.3332 2.82209 11.6544 1.80274 10.6666 1.31184M9.33322 4C9.33322 5.65685 7.99008 7 6.33322 7C4.67637 7 3.33322 5.65685 3.33322 4C3.33322 2.34315 4.67637 1 6.33322 1C7.99008 1 9.33322 2.34315 9.33322 4ZM1.70604 11.6256C2.76892 10.0297 4.44614 9 6.33322 9C8.22031 9 9.89753 10.0297 10.9604 11.6256C11.1932 11.9752 11.3097 12.15 11.2963 12.3733C11.2858 12.5471 11.1719 12.76 11.0329 12.8651C10.8545 13 10.6091 13 10.1183 13H2.54813C2.05734 13 1.81194 13 1.63352 12.8651C1.49459 12.76 1.38062 12.5471 1.37018 12.3733C1.35678 12.15 1.4732 11.9752 1.70604 11.6256Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    "plus-circle": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM11 6C11.5523 6 12 6.44772 12 7V10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H12V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V12H7C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10H10V7C10 6.44772 10.4477 6 11 6Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "minus-circle": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H15C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10H7Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    plus: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M13.5 5C13.5 4.44772 13.0523 4 12.5 4C11.9477 4 11.5 4.44772 11.5 5V11H5.5C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13H11.5V19C11.5 19.5523 11.9477 20 12.5 20C13.0523 20 13.5 19.5523 13.5 19V13H19.5C20.0523 13 20.5 12.5523 20.5 12C20.5 11.4477 20.0523 11 19.5 11H13.5V5Z"
          fill={color}
        />
      </svg>
    ),
    minus: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.5 12C4.5 11.4477 4.94772 11 5.5 11H19.5C20.0523 11 20.5 11.4477 20.5 12C20.5 12.5523 20.0523 13 19.5 13H5.5C4.94772 13 4.5 12.5523 4.5 12Z"
          fill={color}
        />
      </svg>
    ),
    "navigation-pointer": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5951 0.457504C20.1993 0.32529 19.8308 0.420903 19.6476 0.474114C19.448 0.532103 19.2101 0.624997 18.9714 0.718198L2.01429 7.3356C1.74746 7.43969 1.48901 7.54051 1.28953 7.63914C1.11718 7.72436 0.750839 7.91405 0.546353 8.30766C0.32085 8.74172 0.321154 9.25847 0.547167 9.69227C0.752117 10.0856 1.11868 10.2749 1.29113 10.3599C1.49072 10.4583 1.74924 10.5588 2.01619 10.6626L8.72748 13.2725L11.3374 19.9837C11.4412 20.2507 11.5417 20.5093 11.6401 20.7089C11.7251 20.8813 11.9144 21.2479 12.3077 21.4528C12.7415 21.6788 13.2583 21.6791 13.6923 21.4536C14.086 21.2492 14.2756 20.8828 14.3609 20.7105C14.4595 20.511 14.5603 20.2526 14.6644 19.9858L21.2818 3.02861C21.375 2.7899 21.4679 2.55198 21.5259 2.35235C21.5791 2.16916 21.6747 1.8007 21.5425 1.40493C21.3931 0.957786 21.0422 0.606882 20.5951 0.457504Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    "thumbs-up": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.61184 0.833313C9.01183 0.833313 8.4681 1.18667 8.22441 1.73497L5.42378 8.03638C5.35584 8.18926 5.32036 8.2681 5.29128 8.32361L5.28869 8.3285L5.28317 8.32888C5.22063 8.33291 5.13417 8.33331 4.96688 8.33331L4.30126 8.33331C3.86197 8.3333 3.483 8.33328 3.17085 8.35879C2.8414 8.3857 2.51372 8.44513 2.1984 8.6058C1.72799 8.84548 1.34554 9.22793 1.10586 9.69834C0.945198 10.0137 0.885768 10.3413 0.858851 10.6708C0.833348 10.9829 0.833361 11.3619 0.833375 11.8012V15.6988C0.833361 16.138 0.833348 16.517 0.858851 16.8292C0.885768 17.1586 0.945198 17.4863 1.10586 17.8016C1.34554 18.272 1.72799 18.6545 2.1984 18.8942C2.51372 19.0548 2.8414 19.1143 3.17085 19.1412C3.48299 19.1667 3.86195 19.1667 4.30124 19.1666C4.68718 19.1666 5.00004 18.8538 5.00004 18.4678L5.00004 11.6666C5.00004 11.2064 5.37314 10.8333 5.83337 10.8333C6.29361 10.8333 6.66671 11.2064 6.66671 11.6666L6.66671 17.8333C6.66671 18.3 6.66671 18.5334 6.75754 18.7116C6.83743 18.8684 6.96491 18.9959 7.12172 19.0758C7.29998 19.1666 7.53333 19.1666 8.00004 19.1666H13.2664C13.8487 19.1667 14.3321 19.1667 14.7299 19.1364C15.1452 19.1048 15.5281 19.037 15.8977 18.8695C16.4756 18.6076 16.9669 18.1862 17.3136 17.6547C17.5353 17.3149 17.6605 16.9468 17.7549 16.5411C17.8454 16.1526 17.9189 15.6748 18.0074 15.0993L18.4537 12.198C18.571 11.4356 18.6672 10.8105 18.7012 10.3003C18.7363 9.77397 18.7134 9.28283 18.5338 8.81206C18.2581 8.08921 17.7396 7.48477 17.067 7.10235C16.629 6.85329 16.1471 6.75592 15.6215 6.71058C15.1121 6.66662 14.4797 6.66663 13.7082 6.66665H13C12.7529 6.66665 12.6177 6.666 12.5206 6.65806L12.5096 6.65711L12.5086 6.64612C12.5007 6.54896 12.5 6.41375 12.5 6.16665V3.72151C12.5 2.1264 11.207 0.833313 9.61184 0.833313Z"
          fill={color}
        />
      </svg>
    ),
    speaker: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.16087 0.666626H3.83913C3.30249 0.666618 2.85958 0.666611 2.49878 0.696089C2.12405 0.726706 1.77958 0.792416 1.45603 0.957276C0.954261 1.21294 0.546312 1.62089 0.29065 2.12265C0.12579 2.44621 0.0600801 2.79068 0.0294633 3.16541C-1.47695e-05 3.5262 -7.96811e-06 3.96911 2.74432e-07 4.50575V11.4942C-7.96811e-06 12.0308 -1.4772e-05 12.4737 0.0294633 12.8345C0.0600801 13.2092 0.12579 13.5537 0.29065 13.8773C0.546312 14.379 0.954261 14.787 1.45603 15.0426C1.77958 15.2075 2.12405 15.2732 2.49878 15.3038C2.85958 15.3333 3.30248 15.3333 3.83912 15.3333H8.16088C8.69752 15.3333 9.14042 15.3333 9.50122 15.3038C9.87595 15.2732 10.2204 15.2075 10.544 15.0426C11.0457 14.787 11.4537 14.379 11.7094 13.8773C11.8742 13.5537 11.9399 13.2092 11.9705 12.8345C12 12.4737 12 12.0308 12 11.4942V4.50574C12 3.9691 12 3.5262 11.9705 3.16541C11.9399 2.79068 11.8742 2.44621 11.7094 2.12265C11.4537 1.62089 11.0457 1.21294 10.544 0.957276C10.2204 0.792416 9.87595 0.726706 9.50122 0.696089C9.14042 0.666611 8.69751 0.666618 8.16087 0.666626ZM6 2.99996C5.44772 2.99996 5 3.44767 5 3.99996C5 4.55224 5.44772 4.99996 6 4.99996C6.55229 4.99996 7 4.55224 7 3.99996C7 3.44767 6.55229 2.99996 6 2.99996ZM8.66667 9.33329C8.66667 10.8061 7.47276 12 6 12C4.52724 12 3.33333 10.8061 3.33333 9.33329C3.33333 7.86053 4.52724 6.66663 6 6.66663C7.47276 6.66663 8.66667 7.86053 8.66667 9.33329Z"
          fill={color}
        />
      </svg>
    ),
    blinds: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.31201 11H9.35466C9.64709 11 9.89942 11 10.1083 10.9858C10.3281 10.9708 10.5481 10.9378 10.7654 10.8478C11.2554 10.6448 11.6448 10.2554 11.8478 9.76538C11.9378 9.54809 11.9708 9.32815 11.9858 9.10827C12 8.89943 12 8.6471 12 8.35467V2.97868C12 2.68625 12 2.43393 11.9858 2.22509C11.9708 2.0052 11.9378 1.78526 11.8478 1.56798C11.6448 1.07792 11.2554 0.688573 10.7654 0.485586C10.5481 0.395583 10.3281 0.362581 10.1083 0.347578C9.89942 0.333329 9.64709 0.333336 9.35466 0.333344L9.31201 0.333344C9.01958 0.333336 8.76725 0.333329 8.55841 0.347578C8.33853 0.362581 8.11859 0.395583 7.9013 0.485586C7.41125 0.688574 7.0219 1.07792 6.81891 1.56798C6.72891 1.78526 6.6959 2.0052 6.6809 2.22509C6.66665 2.43393 6.66666 2.68625 6.66667 2.97869L6.66667 8.35467C6.66666 8.6471 6.66665 8.89943 6.6809 9.10827C6.6959 9.32815 6.72891 9.54809 6.81891 9.76538C7.0219 10.2554 7.41125 10.6448 7.9013 10.8478C8.11859 10.9378 8.33853 10.9708 8.55841 10.9858C8.76725 11 9.01958 11 9.31201 11Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.64535 13.6667H2.68799C2.98042 13.6667 3.23275 13.6667 3.44159 13.6524C3.66148 13.6374 3.88142 13.6044 4.0987 13.5144C4.58876 13.3115 4.97811 12.9221 5.18109 12.432C5.2711 12.2148 5.3041 11.9948 5.3191 11.7749C5.33335 11.5661 5.33334 11.3138 5.33334 11.0213L5.33333 2.97869C5.33334 2.68625 5.33335 2.43393 5.3191 2.22509C5.3041 2.0052 5.2711 1.78526 5.18109 1.56798C4.97811 1.07792 4.58876 0.688573 4.0987 0.485585C3.88142 0.395582 3.66147 0.36258 3.44159 0.347577C3.23275 0.333329 2.98043 0.333336 2.68799 0.333344L2.64534 0.333344C2.35291 0.333336 2.10058 0.333329 1.89175 0.347577C1.67186 0.36258 1.45192 0.395583 1.23463 0.485585C0.744578 0.688573 0.35523 1.07792 0.152242 1.56798C0.0622391 1.78526 0.0292366 2.0052 0.0142339 2.22509C-1.50725e-05 2.43393 -7.81877e-06 2.68626 3.92496e-07 2.97869L1.09561e-06 11.0213C-7.06453e-06 11.3138 -1.42741e-05 11.5661 0.0142348 11.7749C0.0292375 11.9948 0.06224 12.2148 0.152243 12.432C0.355231 12.9221 0.744579 13.3115 1.23463 13.5144C1.45192 13.6044 1.67186 13.6374 1.89175 13.6524C2.10059 13.6667 2.35291 13.6667 2.64535 13.6667Z"
          fill={color}
        />
      </svg>
    ),
    "volume-max": (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.58197 1.09408C9.80614 0.93282 10.1186 0.983816 10.2798 1.20798C11.0477 2.27533 11.5 3.58556 11.5 4.99997C11.5 6.41437 11.0477 7.7246 10.2798 8.79195C10.1186 9.01612 9.80614 9.06711 9.58197 8.90585C9.35781 8.7446 9.30681 8.43215 9.46807 8.20798C10.1176 7.30509 10.5 6.19793 10.5 4.99997C10.5 3.802 10.1176 2.69484 9.46807 1.79195C9.30681 1.56778 9.35781 1.25534 9.58197 1.09408Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.58661 2.58987C7.8131 2.43189 8.12477 2.48743 8.28275 2.71392C8.73482 3.36202 9 4.15077 9 4.99997C9 5.84916 8.73482 6.63791 8.28275 7.28602C8.12477 7.5125 7.8131 7.56804 7.58661 7.41006C7.36012 7.25208 7.30458 6.94041 7.46256 6.71392C7.80137 6.22819 8 5.63802 8 4.99997C8 4.36191 7.80137 3.77174 7.46256 3.28602C7.30458 3.05953 7.36012 2.74785 7.58661 2.58987Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.19116 0.355832C5.4305 0.336995 5.66439 0.433876 5.82031 0.616434C5.95993 0.779907 5.98211 0.976534 5.99056 1.07198C6.00004 1.17896 6.00002 1.31184 6 1.44703C6 1.45324 6 1.45944 6 1.46565L6 8.5529C6.00002 8.68809 6.00004 8.82097 5.99056 8.92795C5.98211 9.0234 5.95993 9.22003 5.82031 9.3835C5.66439 9.56606 5.4305 9.66294 5.19116 9.6441C4.97684 9.62723 4.82212 9.50388 4.74865 9.44237C4.66629 9.37342 4.57235 9.27945 4.47676 9.18384L2.88076 7.58783C2.83445 7.54152 2.81125 7.51849 2.79369 7.50246L2.79236 7.50126L2.79057 7.50117C2.76682 7.50009 2.73413 7.49997 2.66863 7.49997L1.78406 7.49997C1.65787 7.49998 1.53497 7.5 1.43089 7.49149C1.31659 7.48216 1.18172 7.46012 1.04601 7.39097C0.85785 7.2951 0.704869 7.14212 0.608996 6.95396C0.539847 6.81824 0.517814 6.68338 0.508475 6.56908C0.499972 6.465 0.499986 6.34211 0.500001 6.21592L0.500003 3.79997C0.500003 3.79465 0.500002 3.78933 0.500001 3.78402C0.499986 3.65783 0.499972 3.53493 0.508475 3.43085C0.517814 3.31655 0.539847 3.18169 0.608996 3.04598C0.704869 2.85781 0.85785 2.70483 1.04601 2.60896C1.18172 2.53981 1.31659 2.51778 1.43089 2.50844C1.53497 2.49994 1.65786 2.49995 1.78405 2.49997C1.78936 2.49997 1.79468 2.49997 1.8 2.49997H2.66863C2.73413 2.49997 2.76682 2.49985 2.79057 2.49876L2.79236 2.49868L2.79369 2.49747C2.81125 2.48145 2.83445 2.45841 2.88076 2.4121L4.46361 0.829255C4.468 0.824864 4.47239 0.820475 4.47677 0.816088C4.57235 0.720481 4.6663 0.626507 4.74865 0.557562C4.82212 0.496052 4.97684 0.372699 5.19116 0.355832Z"
          fill={color}
        />
      </svg>
    ),
    "volume-x": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.82031 1.61643C5.66439 1.43388 5.4305 1.337 5.19116 1.35583C4.97684 1.3727 4.82212 1.49605 4.74865 1.55756C4.6663 1.62651 4.57235 1.72048 4.47677 1.81609L2.88076 3.4121C2.83445 3.45841 2.81125 3.48145 2.79369 3.49747L2.79236 3.49868L2.79057 3.49876C2.76682 3.49985 2.73413 3.49997 2.66863 3.49997L1.78405 3.49997C1.65786 3.49995 1.53497 3.49994 1.43089 3.50844C1.31659 3.51778 1.18172 3.53981 1.04601 3.60896C0.85785 3.70483 0.704869 3.85781 0.608996 4.04598C0.539847 4.18169 0.517814 4.31655 0.508475 4.43085C0.499972 4.53493 0.499986 4.65782 0.500001 4.78401L0.500001 7.21592C0.499986 7.3421 0.499972 7.465 0.508475 7.56908C0.517814 7.68338 0.539847 7.81824 0.608996 7.95396C0.704869 8.14212 0.85785 8.2951 1.04601 8.39097C1.18172 8.46012 1.31659 8.48216 1.43089 8.49149C1.53497 8.5 1.65787 8.49998 1.78406 8.49997L2.66863 8.49997C2.73413 8.49997 2.76682 8.50009 2.79057 8.50117L2.79236 8.50126L2.79369 8.50246C2.81125 8.51849 2.83445 8.54152 2.88076 8.58783L4.47676 10.1838C4.57234 10.2794 4.6663 10.3734 4.74865 10.4424C4.82212 10.5039 4.97684 10.6272 5.19116 10.6441C5.4305 10.6629 5.66439 10.5661 5.82031 10.3835C5.95993 10.22 5.98211 10.0234 5.99056 9.92795C6.00004 9.82097 6.00002 9.68809 6 9.5529V2.44703C6.00002 2.31184 6.00004 2.17896 5.99056 2.07198C5.98211 1.97653 5.95993 1.77991 5.82031 1.61643Z"
          fill={color}
        />
        <path
          d="M11.3536 4.14645C11.5488 4.34171 11.5488 4.65829 11.3536 4.85355L10.2071 6L11.3536 7.14645C11.5488 7.34171 11.5488 7.65829 11.3536 7.85355C11.1583 8.04882 10.8417 8.04882 10.6464 7.85355L9.5 6.70711L8.35355 7.85355C8.15829 8.04882 7.84171 8.04882 7.64645 7.85355C7.45118 7.65829 7.45118 7.34171 7.64645 7.14645L8.79289 6L7.64645 4.85355C7.45118 4.65829 7.45118 4.34171 7.64645 4.14645C7.84171 3.95118 8.15829 3.95118 8.35355 4.14645L9.5 5.29289L10.6464 4.14645C10.8417 3.95118 11.1583 3.95118 11.3536 4.14645Z"
          fill={color}
        />
      </svg>
    ),
    ellipse: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 17 16"
        fill="none"
      >
        <circle cx="8.5" cy="8" r="7.5" fill={color} stroke="white" />
      </svg>
    ),
    share: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5286 0.528758C6.78895 0.268409 7.21105 0.268409 7.4714 0.528758L10.1381 3.19543C10.3984 3.45577 10.3984 3.87788 10.1381 4.13823C9.87772 4.39858 9.45561 4.39858 9.19526 4.13823L7.66667 2.60964L7.66667 9.00016C7.66667 9.36835 7.36819 9.66683 7 9.66683C6.63181 9.66683 6.33333 9.36835 6.33333 9.00016L6.33333 2.60964L4.80474 4.13823C4.54439 4.39858 4.12228 4.39858 3.86193 4.13823C3.60158 3.87788 3.60158 3.45577 3.86193 3.19543L6.5286 0.528758Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          d="M3.57493 5.66686L3.66665 5.66689C4.03484 5.66689 4.33332 5.96537 4.33332 6.33356C4.33332 6.70175 4.03484 7.00023 3.66665 7.00023C3.00375 7.00023 2.80308 7.00595 2.65489 7.04566C2.19477 7.16895 1.83537 7.52835 1.71208 7.98847C1.67238 8.13665 1.66665 8.33733 1.66665 9.00023V9.80023C1.66665 10.3713 1.66717 10.7595 1.69168 11.0595C1.71556 11.3518 1.75885 11.5013 1.81197 11.6055C1.93981 11.8564 2.14378 12.0604 2.39466 12.1882C2.49893 12.2414 2.6484 12.2846 2.94067 12.3085C3.24073 12.333 3.62893 12.3336 4.19998 12.3336H9.79998C10.371 12.3336 10.7592 12.333 11.0593 12.3085C11.3516 12.2846 11.501 12.2414 11.6053 12.1882C11.8562 12.0604 12.0602 11.8564 12.188 11.6055C12.2411 11.5013 12.2844 11.3518 12.3083 11.0595C12.3328 10.7595 12.3333 10.3713 12.3333 9.80023V9.00023C12.3333 8.33733 12.3276 8.13665 12.2879 7.98847C12.1646 7.52835 11.8052 7.16895 11.3451 7.04566C11.1969 7.00595 10.9962 7.00023 10.3333 7.00023C9.96513 7.00023 9.66665 6.70175 9.66665 6.33356C9.66665 5.96537 9.96513 5.66689 10.3333 5.66689L10.425 5.66686C10.9553 5.66657 11.3491 5.66636 11.6902 5.75776C12.6104 6.00434 13.3292 6.72313 13.5758 7.64338C13.6672 7.98448 13.667 8.37825 13.6667 8.90851L13.6666 9.82778C13.6667 10.3644 13.6667 10.8073 13.6372 11.1681C13.6066 11.5428 13.5409 11.8873 13.376 12.2109C13.1203 12.7126 12.7124 13.1206 12.2106 13.3762C11.8871 13.5411 11.5426 13.6068 11.1679 13.6374C10.8071 13.6669 10.3642 13.6669 9.8275 13.6669H4.17247C3.63581 13.6669 3.1929 13.6669 2.8321 13.6374C2.45737 13.6068 2.1129 13.5411 1.78934 13.3762C1.28758 13.1206 0.879628 12.7126 0.623966 12.2109C0.459105 11.8873 0.393395 11.5428 0.362779 11.1681C0.3333 10.8073 0.333307 10.3644 0.333316 9.82776L0.333283 8.90851C0.332996 8.37825 0.332783 7.98448 0.424181 7.64338C0.67076 6.72313 1.38955 6.00434 2.3098 5.75776C2.6509 5.66636 3.04467 5.66657 3.57493 5.66686Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    grid: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.04545 0.333498C2.90407 0.333604 3.76437 0.333604 4.62131 0.333498C4.78956 0.333478 4.95343 0.333458 5.0922 0.344796C5.24459 0.357248 5.42442 0.386626 5.60536 0.478824C5.85625 0.606655 6.06022 0.810629 6.18805 1.06151C6.28025 1.24246 6.30963 1.42228 6.32208 1.57468C6.33342 1.71346 6.3334 1.87732 6.33338 2.04558V4.62142C6.3334 4.78968 6.33342 4.95354 6.32208 5.09232C6.30963 5.24472 6.28025 5.42454 6.18805 5.60549C6.06022 5.85637 5.85625 6.06034 5.60536 6.18817C5.42442 6.28037 5.24459 6.30975 5.0922 6.3222C4.95342 6.33354 4.78956 6.33352 4.6213 6.3335H2.04546C1.8772 6.33352 1.71333 6.33354 1.57456 6.3222C1.42216 6.30975 1.24234 6.28037 1.06139 6.18817C0.810507 6.06034 0.606533 5.85637 0.478702 5.60549C0.386504 5.42454 0.357125 5.24472 0.344674 5.09232C0.333336 4.95355 0.333356 4.78969 0.333376 4.62143C0.333377 4.61435 0.333377 4.60726 0.333377 4.60017V2.06683C0.333377 2.05974 0.333377 2.05265 0.333376 2.04557C0.333356 1.87731 0.333336 1.71345 0.344674 1.57468C0.357125 1.42228 0.386504 1.24246 0.478702 1.06151C0.606533 0.810629 0.810507 0.606655 1.06139 0.478824C1.24234 0.386626 1.42216 0.357248 1.57456 0.344796C1.71333 0.333458 1.87719 0.333478 2.04545 0.333498Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.04545 7.66683C2.90407 7.66694 3.76437 7.66694 4.62131 7.66683C4.78956 7.66681 4.95343 7.66679 5.0922 7.67813C5.24459 7.69058 5.42442 7.71996 5.60536 7.81216C5.85625 7.93999 6.06022 8.14396 6.18805 8.39485C6.28025 8.5758 6.30963 8.75562 6.32208 8.90801C6.33342 9.04679 6.3334 9.21065 6.33338 9.37891V11.9548C6.3334 12.123 6.33342 12.2869 6.32208 12.4257C6.30963 12.578 6.28025 12.7579 6.18805 12.9388C6.06022 13.1897 5.85625 13.3937 5.60536 13.5215C5.42442 13.6137 5.24459 13.6431 5.0922 13.6555C4.95342 13.6669 4.78956 13.6669 4.6213 13.6668H2.04546C1.8772 13.6669 1.71333 13.6669 1.57456 13.6555C1.42216 13.6431 1.24234 13.6137 1.06139 13.5215C0.810507 13.3937 0.606533 13.1897 0.478702 12.9388C0.386504 12.7579 0.357125 12.578 0.344674 12.4257C0.333336 12.2869 0.333356 12.123 0.333376 11.9548C0.333377 11.9477 0.333377 11.9406 0.333377 11.9335V9.40017C0.333377 9.39307 0.333377 9.38598 0.333376 9.3789C0.333356 9.21065 0.333336 9.04679 0.344674 8.90801C0.357125 8.75562 0.386504 8.5758 0.478702 8.39485C0.606533 8.14396 0.810507 7.93999 1.06139 7.81216C1.24234 7.71996 1.42216 7.69058 1.57456 7.67813C1.71333 7.66679 1.87719 7.66681 2.04545 7.66683Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.37878 0.333498C10.2374 0.333604 11.0977 0.333604 11.9546 0.333498C12.1229 0.333478 12.2868 0.333458 12.4255 0.344796C12.5779 0.357248 12.7577 0.386626 12.9387 0.478824C13.1896 0.606655 13.3936 0.810629 13.5214 1.06151C13.6136 1.24246 13.643 1.42228 13.6554 1.57468C13.6668 1.71346 13.6667 1.87732 13.6667 2.04558V4.62142C13.6667 4.78968 13.6668 4.95354 13.6554 5.09232C13.643 5.24472 13.6136 5.42454 13.5214 5.60549C13.3936 5.85637 13.1896 6.06034 12.9387 6.18817C12.7577 6.28037 12.5779 6.30975 12.4255 6.3222C12.2868 6.33354 12.1229 6.33352 11.9546 6.3335H9.37879C9.21053 6.33352 9.04667 6.33354 8.90789 6.3222C8.7555 6.30975 8.57567 6.28037 8.39472 6.18817C8.14384 6.06034 7.93987 5.85637 7.81204 5.60549C7.71984 5.42454 7.69046 5.24472 7.67801 5.09232C7.66667 4.95355 7.66669 4.78969 7.66671 4.62143C7.66671 4.61435 7.66671 4.60726 7.66671 4.60017V2.06683C7.66671 2.05974 7.66671 2.05265 7.66671 2.04557C7.66669 1.87731 7.66667 1.71345 7.67801 1.57468C7.69046 1.42228 7.71984 1.24246 7.81204 1.06151C7.93987 0.810629 8.14384 0.606655 8.39472 0.478824C8.57567 0.386626 8.7555 0.357248 8.90789 0.344796C9.04666 0.333458 9.21052 0.333478 9.37878 0.333498Z"
          fill={color}
          fill-opacity="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.37878 7.66683C10.2374 7.66694 11.0977 7.66694 11.9546 7.66683C12.1229 7.66681 12.2868 7.66679 12.4255 7.67813C12.5779 7.69058 12.7577 7.71996 12.9387 7.81216C13.1896 7.93999 13.3936 8.14396 13.5214 8.39485C13.6136 8.5758 13.643 8.75562 13.6554 8.90801C13.6668 9.04679 13.6667 9.21065 13.6667 9.37891V11.9548C13.6667 12.123 13.6668 12.2869 13.6554 12.4257C13.643 12.578 13.6136 12.7579 13.5214 12.9388C13.3936 13.1897 13.1896 13.3937 12.9387 13.5215C12.7577 13.6137 12.5779 13.6431 12.4255 13.6555C12.2868 13.6669 12.1229 13.6669 11.9546 13.6668H9.37879C9.21053 13.6669 9.04667 13.6669 8.90789 13.6555C8.7555 13.6431 8.57567 13.6137 8.39472 13.5215C8.14384 13.3937 7.93987 13.1897 7.81204 12.9388C7.71984 12.7579 7.69046 12.578 7.67801 12.4257C7.66667 12.2869 7.66669 12.123 7.66671 11.9548C7.66671 11.9477 7.66671 11.9406 7.66671 11.9335V9.40017C7.66671 9.39307 7.66671 9.38598 7.66671 9.3789C7.66669 9.21065 7.66667 9.04679 7.67801 8.90801C7.69046 8.75562 7.71984 8.5758 7.81204 8.39485C7.93987 8.14396 8.14384 7.93999 8.39472 7.81216C8.57567 7.71996 8.7555 7.69058 8.90789 7.67813C9.04666 7.66679 9.21052 7.66681 9.37878 7.66683Z"
          fill={color}
          fill-opacity="0.6"
        />
      </svg>
    ),
    account: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.27866 11.2021C2.59397 9.46134 4.66582 8.33334 6.9999 8.33334C9.33398 8.33334 11.4058 9.46134 12.7211 11.2021C12.7271 11.21 12.7331 11.2178 12.7391 11.2258C12.8521 11.3753 12.9734 11.5357 13.0601 11.6904C13.165 11.8776 13.2427 12.0943 13.2379 12.3577C13.234 12.5695 13.1698 12.7677 13.0919 12.9243C13.014 13.0808 12.8945 13.2515 12.7279 13.3823C12.5057 13.5566 12.2637 13.6184 12.0488 13.6442C11.8602 13.6668 11.6368 13.6667 11.4121 13.6667C11.402 13.6667 11.3919 13.6667 11.3817 13.6667H2.61806C2.60793 13.6667 2.59781 13.6667 2.58768 13.6667C2.36302 13.6667 2.13956 13.6668 1.951 13.6442C1.73607 13.6184 1.49413 13.5566 1.27192 13.3823C1.10529 13.2515 0.985802 13.0808 0.907885 12.9243C0.829967 12.7677 0.765804 12.5695 0.761931 12.3577C0.757113 12.0943 0.834852 11.8776 0.939742 11.6904C1.0264 11.5357 1.14766 11.3753 1.26075 11.2258C1.26675 11.2178 1.27272 11.2099 1.27866 11.2021Z"
          fill="#181818"
          fill-opacity="0.87"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.33324 4.00001C3.33324 1.97497 4.97486 0.333344 6.9999 0.333344C9.02495 0.333344 10.6666 1.97497 10.6666 4.00001C10.6666 6.02505 9.02495 7.66668 6.9999 7.66668C4.97486 7.66668 3.33324 6.02505 3.33324 4.00001Z"
          fill={color}
          fill-opacity="0.87"
        />
      </svg>
    ),
    bookmark: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.50564 0.333344H7.49404C8.03068 0.333335 8.47359 0.333328 8.83439 0.362807C9.20912 0.393423 9.55359 0.459133 9.87715 0.623993C10.3789 0.879656 10.7869 1.2876 11.0425 1.78937C11.2074 2.11293 11.2731 2.4574 11.3037 2.83213C11.3332 3.19293 11.3332 3.63584 11.3332 4.17249V13C11.3332 13.2377 11.2067 13.4573 11.0011 13.5766C10.7956 13.6959 10.5421 13.6967 10.3357 13.5788L5.99984 11.1012L1.66393 13.5788C1.4576 13.6967 1.2041 13.6959 0.998553 13.5766C0.79301 13.4573 0.666505 13.2377 0.666505 13L0.666504 4.17248C0.666496 3.63583 0.666489 3.19292 0.695967 2.83213C0.726584 2.4574 0.792293 2.11293 0.957154 1.78937C1.21282 1.2876 1.62076 0.879656 2.12253 0.623993C2.44609 0.459133 2.79056 0.393423 3.16529 0.362807C3.52608 0.333328 3.96899 0.333335 4.50564 0.333344Z"
          fill={color}
          fill-opacity="0.87"
        />
      </svg>
    ),
    logout: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.15623 0.592359C3.48659 0.605466 3.88996 0.65029 4.38099 0.704855L5.11468 0.786372C5.28265 0.805006 5.40753 0.818859 5.52019 0.84126C6.68448 1.07275 7.55556 2.04598 7.65706 3.22871C7.66689 3.34316 7.66686 3.4688 7.66683 3.6378V3.93332C7.66683 4.30669 7.66683 4.49338 7.59417 4.63598C7.53026 4.76143 7.42827 4.86341 7.30283 4.92733C7.16022 4.99999 6.97354 4.99999 6.60017 4.99999H5.00016C3.89559 4.99999 3.00016 5.89542 3.00016 6.99999C3.00016 8.10456 3.89559 8.99999 5.00016 8.99999H6.60017C6.97354 8.99999 7.16022 8.99999 7.30283 9.07265C7.42827 9.13657 7.53026 9.23855 7.59417 9.364C7.66683 9.5066 7.66683 9.69329 7.66683 10.0667V10.3622C7.66686 10.5312 7.66689 10.6568 7.65706 10.7712C7.55556 11.954 6.68448 12.9272 5.52019 13.1587C5.40754 13.1811 5.28265 13.195 5.11472 13.2136L4.40637 13.2923L4.40506 13.2924L4.38092 13.2951C3.88992 13.3497 3.48657 13.3945 3.15622 13.4076C2.81533 13.4211 2.49714 13.4044 2.18742 13.3052C1.46499 13.0741 0.8752 12.5462 0.565656 11.8537C0.432947 11.5568 0.381146 11.2424 0.356942 10.9021C0.333486 10.5723 0.333491 10.1665 0.333496 9.67244V4.32753C0.333491 3.83349 0.333486 3.42762 0.356942 3.09784C0.381146 2.75753 0.432947 2.44314 0.565656 2.14626C0.8752 1.45378 1.46499 0.925892 2.18742 0.694712C2.49714 0.5956 2.81533 0.578835 3.15623 0.592359Z"
          fill={color}
        />
        <path
          d="M9.19542 3.19525C9.45577 2.9349 9.87788 2.9349 10.1382 3.19525L13.4716 6.52859C13.7319 6.78893 13.7319 7.21105 13.4716 7.47139L10.1382 10.8047C9.87788 11.0651 9.45577 11.0651 9.19542 10.8047C8.93507 10.5444 8.93507 10.1223 9.19542 9.86192L11.3907 7.66666H5.00016C4.63197 7.66666 4.3335 7.36818 4.3335 6.99999C4.3335 6.6318 4.63197 6.33332 5.00016 6.33332H11.3907L9.19542 4.13806C8.93507 3.87771 8.93507 3.4556 9.19542 3.19525Z"
          fill={color}
        />
      </svg>
    ),
    layers: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.76942 0.903943C9.92176 0.875362 10.0781 0.875362 10.2304 0.903943C10.4108 0.937782 10.5717 1.01989 10.6494 1.05957C10.6574 1.06365 10.6646 1.06729 10.6707 1.07038L18.7059 5.08797C18.9882 5.22913 19.1666 5.51768 19.1666 5.83333C19.1666 6.14897 18.9882 6.43752 18.7059 6.57868L10.6707 10.5963C10.6646 10.5994 10.6574 10.603 10.6494 10.6071C10.5717 10.6468 10.4108 10.7289 10.2304 10.7627C10.0781 10.7913 9.92176 10.7913 9.76942 10.7627C9.58905 10.7289 9.42815 10.6468 9.3504 10.6071C9.3424 10.603 9.33527 10.5994 9.3291 10.5963L1.29391 6.57868C1.01159 6.43752 0.833252 6.14897 0.833252 5.83333C0.833252 5.51768 1.01159 5.22913 1.29391 5.08797L9.3291 1.07038C9.33527 1.06729 9.3424 1.06365 9.35041 1.05956C9.42815 1.01989 9.58906 0.937782 9.76942 0.903943Z"
          fill={color}
          fill-opacity="0.87"
        />
        <path
          d="M2.10633 8.84834L1.29391 9.25455C1.01159 9.39571 0.833252 9.68426 0.833252 9.99991C0.833252 10.3155 1.01159 10.6041 1.29391 10.7453L9.3291 14.7629L9.3504 14.7737C9.42815 14.8133 9.58905 14.8954 9.76942 14.9293C9.92176 14.9579 10.0781 14.9579 10.2304 14.9293C10.4108 14.8955 10.5717 14.8133 10.6494 14.7737L10.6707 14.7629L18.7059 10.7453C18.9882 10.6041 19.1666 10.3155 19.1666 9.99991C19.1666 9.68426 18.9882 9.39571 18.7059 9.25455L17.8935 8.84834L11.4161 12.087L11.4063 12.092C11.3656 12.1124 11.2655 12.1626 11.1653 12.2062C11.0317 12.2644 10.8153 12.3488 10.5377 12.4008C10.1823 12.4675 9.81754 12.4675 9.46209 12.4008C9.18449 12.3488 8.96812 12.2644 8.83449 12.2062C8.73434 12.1626 8.63429 12.1124 8.59358 12.092L8.58374 12.087L2.10633 8.84834Z"
          fill={color}
          fill-opacity="0.87"
        />
        <path
          d="M2.10645 13.0149L1.29391 13.4212C1.01159 13.5624 0.833252 13.8509 0.833252 14.1666C0.833252 14.4822 1.01159 14.7708 1.29391 14.9119L9.3291 18.9295L9.3504 18.9403C9.42815 18.98 9.58905 19.0621 9.76942 19.096C9.92176 19.1245 10.0781 19.1245 10.2304 19.096C10.4108 19.0621 10.5717 18.98 10.6494 18.9403L10.6707 18.9295L18.7059 14.9119C18.9882 14.7708 19.1666 14.4822 19.1666 14.1666C19.1666 13.8509 18.9882 13.5624 18.7059 13.4212L17.8934 13.0149L11.4161 16.2536L11.4063 16.2585C11.3656 16.279 11.2655 16.3292 11.1653 16.3728C11.0317 16.4309 10.8153 16.5153 10.5377 16.5674C10.1823 16.6341 9.81754 16.6341 9.46209 16.5674C9.18449 16.5153 8.96812 16.4309 8.83449 16.3728C8.73434 16.3292 8.63429 16.279 8.59358 16.2585L8.58374 16.2536L2.10645 13.0149Z"
          fill={color}
          fill-opacity="0.87"
        />
      </svg>
    ),
  };
  // identify icon by prop name
  let xml = icons[name];
  //    return svg icon as Component for Ui
  return xml;
};

export default IconComponent;
