import React, { useContext, useState } from 'react'
import IconComponent from '../../common/icons/IconComponent'
import styles from './displays.module.css'
import { GlobalContext } from '../../../context/provider'

const Displays = ({activeWidget,setactiveWidget,setActiveWidgetColor}) => {
  const [selected, setSelected] = useState(false)
  const { state, dispatch } = useContext(GlobalContext);
  const displays =  state.floorAssets.res.filter((asset)=> asset.device.subtype === 'screen')
  const total = displays.length
  const activeDisplays = displays.filter((display)=> display.device.properties[0].value === 'on')
  const noOfActiveDisplays = activeDisplays.length
  
  /* Handle click sets widget color & selects widget */
  const handleClick = () => {
    
    if(activeWidget !== 'Displays'){
      setactiveWidget('Displays')
      setActiveWidgetColor("#558CDD")
      setSelected(true)
    }else{
      setactiveWidget(null)
      setActiveWidgetColor(null)
      setSelected(false)
    }
  }

  return (
    <div 
      className={selected && activeWidget === 'Displays' ? `card-widget ${styles.cardWidget__selected}`: 'card-widget'} 
      onClick={handleClick}
    >
      { 
        /* Selected widget Content */
        selected && activeWidget === 'Displays'
        ?(
          <div className='card-widget__selected__content'>
            <p>Longest Screen On Time is in <span className='zoneText'>Zone 1</span></p>
          </div>
        ):(
          <>
            <IconComponent name="monitor" width={24} height={24} color="#74A1E4"/>
            {/* Card Widget Content  */}
            <div className='card-widget__content'>
              <h4 className='card-widget__title'>Displays</h4>
              <div className={styles.cardWidget__data}>
                <div className='card-widget__valueContainer'>
                  <div className='card-widget__value'>{noOfActiveDisplays} <span>Zones On</span></div>
                </div>
                <div className='card-widget__valueContainer'>
                  <div className={styles.cardWidget__totalValue}>{total} <span>Total</span></div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

export default Displays