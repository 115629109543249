import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './navbar.module.css'
import IconComponent from '../icons/IconComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../context/authProvider'
import { logout } from '../../../context/actions/authActions'
import OutsideClick from '../../../pages/zonePage/OutsideClick'
import { GlobalContext } from '../../../context/provider'
import { getFloor, getFloorBasedAssetList, getFloorBasedRoomList } from '../../../context/actions/floorActions'
import { getBdAssetList } from '../../../context/actions/zoneActions'

const Navbar = ({activeWidget,activeWidgetColor,onZone,selectedFloor,floors,siteList,sidebar,zoomToElement,setactiveWidget}) => {
  const navigate=useNavigate()
  const { state, dispatch } = useContext(GlobalContext);
  const location = useLocation();
  const[status,setStatus]=useState(false)
  const[openSidebar,setOpenSidebar]=useState(false)
  const[selectFloor,setSelectFloor]=useState(selectedFloor)
  const[selectedItem,setSelectedItem]=useState(selectedFloor.floorName)
  const[selectedZone,setSelectedZone]=useState(onZone)
  const logoutRef = useRef(null);
  const sidebarRef = useRef(null);
  const outsideClick = OutsideClick(sidebarRef,setOpenSidebar)
  const logoutOutsideClick = OutsideClick(logoutRef,setStatus)
  const {authState,authDispatch} = useContext(AuthContext)

  useEffect(() => {
    const accountInfo = {
      AccountId: localStorage.getItem("AccountId"),
      SiteId: localStorage.getItem("SiteId"),
    };
    getFloor(dispatch,{...accountInfo,FloorId:selectFloor.id})
    getFloorBasedRoomList(dispatch,{...accountInfo,FloorId:selectFloor.id})
    getFloorBasedAssetList(dispatch,{...accountInfo,FloorId:selectFloor.id})
    if (setactiveWidget) setactiveWidget(null)
  },[selectFloor])

  /* Toggles login modal */
  const handleClick=()=>{
    setStatus(!status)
  }

  /* Logs out current user */
  const handleLogut=()=>{
    logout(authDispatch)
    navigate('/',{replace:true})
  }

  /* Toggles floor sidebar */
   const handleSidebar=()=>{
    setOpenSidebar(!openSidebar)
  }

  /* Selects a particular floor */
  const handleFloorSelect = (item) => {
    setSelectedItem(item.floorName)
    setSelectFloor(item)
  }
  const handleZoneSelect=(item)=>{
     setSelectedZone(item.friendlyName)
     const zoneInfo = {
      AccountId: localStorage.getItem("AccountId"),
      SiteId: localStorage.getItem("SiteId"),
      RoomId: item.id
    }
    getBdAssetList(dispatch,zoneInfo)
    navigate("/zone", { 
      state: {
        areaId: item.id, 
        floor: state.floor.res,
        roomName: item.roomName,
        item
      } 
    });
    // getting this zoomToElement from props and call after 1 section handle click function render 
    setTimeout(()=>{
      zoomToElement(item.id,2)
    },1000)
 } 

  return (
    <div className={styles.navbar_container}>
    <div className={styles.navbar} data-testid="navbar-container">
      <div className={styles.navbar__left}>
      <div className={styles.navbar_floor_icon} onClick={handleSidebar}>
          <IconComponent name="layers" width={20} height={20} color="#181818"/>
        </div>
        <div className={styles.navbar__left__chevronContainer}>
          {
            onZone
            ? <div onClick={()=>navigate(-1)} data-testid="chevron-left"><IconComponent name="chevron-left" width={25} height={25} color={"#18181899"}/></div>
            : <div onClick={()=>navigate(-1)} data-testid="chevron-left"><IconComponent name="chevron-left" width={25} height={25} color={"#18181861"}/></div>
          }
          <div onClick={()=>navigate(+1)} data-testid="chevron-right"><IconComponent name="chevron-right" width={25} height={25} color={"#18181861"}/></div>
        </div>
        <div className={styles.navbar__left__navInfo}>
          <span className={styles.navbar__left__navInfoName}>{siteList}</span>
          <IconComponent name="chevron-right" width={16} height={16} color={"#000000"}/>
          <div className={styles.navbar__left__floorInfo}>
            <div className={ onZone ? `${styles.navbar__left__zoneFloorText}`:`${styles.navbar__left__floorText}`}>{selectedItem} </div>
            {
              activeWidget 
              ? (
                  <span 
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: 20,
                      lineHeight: 24,
                      color: activeWidgetColor
                    }}
                  > {activeWidget}
                  </span>
                )
              : onZone ? (
                <>
                  <IconComponent name="chevron-right" width={16} height={16} color={"#000000"}/>
                  <span 
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 20,
                      lineHeight: 24,
                      color: 'rgba(24, 24, 24, 0.87)'
                    }}
                  >
                    {onZone}
                  </span>
                </>
                )
              : null
            }
            <IconComponent name="chevron-down" width={16} height={16} color={"#000000"}/>
          </div>
        </div>
      </div>
      <div className={styles.navbar__right}>
        <div className={styles.navbar__right__buttonContainer}>
          <button className={styles.navbar__right__dashboardButton}>
            <span className={styles.navbar__right__buttonText}>Go to Dashboard</span>
            <IconComponent name="chart-breakout-square" width={16} height={16} color={"#18181899"}/>
          </button>
        </div>
        <div onClick={handleClick}>
          <img 
          style={{
            border: '2px solid #B575C4',
            borderRadius: 100
          }}
           src="./User.png" 
           alt="avatar" 
          />
        </div>
        {status?
        /* Profile Options modal */
        <div className={styles.navbar_logout_container} ref={logoutRef}>
          <div className={styles.navbar_logout_options}>
            <IconComponent name="account" width={14} height={14} color="#181818"/>
            <p>Account Details</p>
          </div>
          <div className={styles.navbar_logout_options}>
            <IconComponent name="bookmark" width={12} height={14} color="#181818"/>
            <p>Report a problem</p>
          </div>
          <div className={`${styles.navbar_logout_options} , ${styles.logout}`} onClick={handleLogut}>
            <IconComponent name="logout" width={14} height={14} color="#F24242"/>
            <p>Log out</p>
          </div>
        </div>: <div></div>}
      </div>
     
    </div>
     {  
        /* Floor Selection Sidebar  && Zone Sidebar*/
        openSidebar?(
           <div className={styles.Sidebar_container} ref={sidebarRef}>
          <div className={styles.navbar_floor_sidebar_heading}>
            {sidebar}
          </div>
          {sidebar === 'Floors' ? floors.res.map((item,index)=>( 
            <div key={index} className={styles.sidebar_list_data} onClick={()=> {handleFloorSelect(item)}}
            style={{
              color:item.floorName===selectedItem ?"white":'',
              backgroundColor: item.floorName===selectedItem ? "#B575C4" : "",
              border:  item.floorName===selectedItem? '2px solid rgba(181, 117, 196, 0.25)':null
            }}>
              <div className={styles.sidebar_list}>{item.floorName}</div> 
            </div>
          ))// zone Sidebar Render 
          :state.floorRooms.res.map((item,index)=>(
            <div key={index} className={styles.sidebar_list_data} onClick={()=> {handleZoneSelect(item)}}
            style={{
              color:item.friendlyName===selectedZone ?"white":'',
              backgroundColor: item.friendlyName===selectedZone ? "#B575C4" : "",
              border:  item.friendlyName===selectedZone? '2px solid rgba(181, 117, 196, 0.25)':null
            }}>
              <div className={styles.sidebar_list}>{item.friendlyName}</div> 
            </div>
          )) }
          </div>
        ):null
      }
    </div>
  )
}

export default Navbar