import React, { useState } from "react";
import IconComponent from "../../common/icons/IconComponent";
import styles from "./displayScreen.module.css";
import Dropdown from "../../common/dropdown/Dropdown";
import ToggleButton from "../../common/toggleButton/ToggleButton";
import Slider from "../../common/slider/Slider";
import Volume from "../../common/volume/Volume";
import Status from "../../common/status/Status";

const DisplayScreen = ({
  setOpenSidebar,
  setComponents,
  setSidebarColor,
  setCurrentSidebar,
  source,
  deviceOn,
  setDeviceOn,
  setSource,
  deviceDetail,

}) => {
  const [selectedOption, setSelectedOption] = useState(source);
  const options = deviceDetail.digispace.properties.slice(-1)[0].value.map((dropdownValue,index) => dropdownValue.friendly_name);
  
  /* handleClick passes all the data for Display sidebar to render */
  const handleClick = () => {
    setOpenSidebar(true);
    setCurrentSidebar('Display Screen')
    setComponents({
      status: deviceOn,
      // status: deviceOn ? "Device is On" : "Device is Off",
      // statusIcon: deviceOn ? (
      //   <IconComponent
      //     name="ellipse"
      //     width={16}
      //     height={16}
      //     color={"#31BF8C"}
      //   />
      // ) : (
      //   <IconComponent
      //     name="ellipse"
      //     width={16}
      //     height={16}
      //     color={"#F3C624"}
      //   />
      // ),
      icon: "monitor",
      title: "Display Controller",
      dropdown: (
        <Dropdown
          options={options}
          selectedOption={source}
          setSelectedOption={setSource}
          deviceData={deviceDetail}
        />
      ),
      slider: (
        <Slider
          sliderLevel={
            deviceDetail.device.properties[2].control.options 
            ? deviceDetail.device.properties[2].control.options 
            : deviceDetail.device.properties[2].control
          }
          sliderValue={deviceDetail.device.properties[2].value}
          deviceData={deviceDetail}
        />
      ),
      toggle: (
        <ToggleButton
          toggle={setDeviceOn}
          value={deviceOn}
          color={"#74A1E4"}
          setColor={setSidebarColor}
          deviceData={deviceDetail}
        />
      ),
      volume: (
        <Volume
          volumeStatus={
            deviceDetail.device.properties[1].value === "on" ? true : false
          }
          deviceData={deviceDetail}
        />
      ),
      device: deviceDetail.asset_name,
    });
    setSidebarColor(deviceOn ? "#74A1E4" : "#BFBFBF");
  };
  return (
    <div className="card-widget-devices" onClick={handleClick}>
      {/* Device Heading */}
      <div className="card-widget-devices__titleRow">
        <div className="card-widget-devices__heading">
          <IconComponent
            name="monitor"
            width={16}
            height={16}
            color={"#74A1E4"}
          />
          <h4 className="card-widget-devices__title">{deviceDetail.asset_name}</h4>
        </div>
        <div
          style={{
            background: "#74A1E4",
            borderRadius: 100,
            width: 20,
            height: 20,
            padding: "1px 4px",
          }}
        >
          <IconComponent
            name={deviceDetail.device.properties[1].value === 'on' ? 'volume-x': 'volume-max'}
            width={12}
            height={12}
            color={"#ffffff"}
          />
        </div>
      </div>

      {/* Device data */}
      <div className={styles.cardWidgetDevices__content}>
        <span className={styles.cardWidgetDevices__content__text}>
          Current source is
        </span>
        <span className={styles.cardWidgetDevices__content__sourceText}>
          {selectedOption}
        </span>
      </div>
    </div>
  );
};

export default DisplayScreen;
