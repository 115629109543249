import React, { useContext, useEffect, useState } from 'react'
import styles from "./blindControls.module.css";
import IconComponent from '../common/icons/IconComponent';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { commandRequest } from '../../context/actions/zoneActions';
import { GlobalContext } from '../../context/provider';

const BlindControls = ({deviceData,setColor,color,deviceData:{device,digispace,asset_id,id,controller_config}}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const [value, setValue] = useStateWithCallbackLazy()

  const handleClick = (action) => {
    setValue(action,(value) => {
      const commandRequestPayload = {
        AssetId: id,
        Properties: device.properties.map((property,index) => {
          if(property.label === 'Open'){
            return {
              ...property,
              value: value === 'up' ? true: false
            }
          }else if (property.label === 'Close') {
            return {
              ...property,
              value: value === 'down' ? true: false
            }
          }
          else {
            return property
          }
        }),
        
        RequestCommand: {
          action: "blind",
          action_values:  ["blind"],
          executed: false,
          meta: {
            asset_id: asset_id,
            port: digispace.properties.filter((property) => property.name === value)[0].value
          },
          type: "SET",
          value: value === 'down' ? 'down': 'up'
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
      
      commandRequest(dispatch,commandRequestPayload)
    })
  }

  return (
    <div className={styles.blindControls}>
      <div className={value === 'up' ? styles.blindControls__highlight :styles.blindControls__up} onClick={(e)=> handleClick('up')}>
        <IconComponent name='arrow-up' width={24} height={24} color={value === 'up' ?'#000000': '#ffffff'} />
      </div>
      <div className={value === 'down' ? styles.blindControls__highlight: styles.blindControls__down} onClick={(e)=> handleClick('down')}>
        <IconComponent name='arrow-down' width={24} height={24} color={value === 'down' ?'#000000':'#ffffff'} />
      </div>
    </div>
  )
}

export default BlindControls