import axios from "axios"
import http from "../../services/httpService";
import { API_BASE_URL } from "../../constants/apiConstants"
import { GET_ASSETLIST, GET_FLOOR, GET_FLOORLIST, GET_FLOOR_ASSETLIST, GET_FLOOR_ROOMLIST, GET_SITELIST, LOADING } from "../../constants/types"




export const getViewSiteList = async(dispatch,accountInfo) => {
  http.setHeaders()
  // dispatch({type:LOADING})
  const viewSiteList = await http.post(`${API_BASE_URL}/siteapi/GetViewSitelist`, accountInfo)
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))

  const updatedCurrentUser = {
    ...currentUser,
    viewSiteListName: viewSiteList.data.res.name
  }

  localStorage.setItem('currentUser',JSON.stringify(updatedCurrentUser))
  dispatch({
    type: GET_SITELIST,
    payload: viewSiteList.data
  })
}

export const getFloorList = async(dispatch,accountInfo) => {
  http.setHeaders()
  // dispatch({type:LOADING})
  const floorList = await http.post(`${API_BASE_URL}/floorapi/getFloorList`, accountInfo)
  dispatch({
    type: GET_FLOORLIST,
    payload: floorList.data
  })
  return floorList.data
}
export const getAssetList = async(dispatch,accountInfo) => {
  http.setHeaders()
  // dispatch({type:LOADING})
  const assetList = await http.post(`${API_BASE_URL}/assetapi/getAssetList`, accountInfo)
  dispatch({
    type: GET_ASSETLIST,
    payload: assetList.data
  })
}
export const getFloor = async(dispatch,accountInfo) => {
  http.setHeaders()
  // dispatch({type:LOADING})
  const floorData = await http.post(`${API_BASE_URL}/floorapi/getFloor`, accountInfo)
  dispatch({
    type: GET_FLOOR,
    payload: floorData.data
  })
}
export const getFloorBasedRoomList = async(dispatch,accountInfo) => {
  http.setHeaders()
  // dispatch({type:LOADING})
  const floorRooms = await http.post(`${API_BASE_URL}/roomapi/getFloorBasedRoomList`, accountInfo)
  dispatch({
    type: GET_FLOOR_ROOMLIST,
    payload: floorRooms.data
  })
}  
export const getFloorBasedAssetList = async(dispatch,accountInfo) => {
  http.setHeaders()
  // dispatch({type:LOADING})
  const floorAssets = await http.post(`${API_BASE_URL}/assetapi/getFloorBasedassetlist`, accountInfo)
  dispatch({
    type: GET_FLOOR_ASSETLIST,
    payload: floorAssets.data
  })
}
