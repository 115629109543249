import React, { useContext, useEffect, useState } from 'react'
import IconComponent from '../icons/IconComponent'
import styles from "./volume.module.css";
import { commandRequest } from '../../../context/actions/zoneActions';
import { GlobalContext } from '../../../context/provider';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const Volume = ({volumeStatus,deviceData:{device,digispace,asset_id,id}}) => {

  const { state, dispatch } = useContext(GlobalContext);
  const [muted, setMuted] = useStateWithCallbackLazy(volumeStatus)

  
  const handleClick = () => {
    setMuted(!muted,(muted) => {
      const commandRequestPayload = {
        AssetId: id,
        Properties: device.properties.map((property,index) => {
          if(property.label === 'audiomute'){
            return {
              ...property,
              value: muted ? "on": "off"
            }
          }
          else {
            return property
          }
        }),
        RequestCommand: {
          action: "audiomute",
          action_values: ["power", "source", "volume"],
          executed: false,
          meta:( 
            device.subtype === 'audio'
            ? {
                asset_id: asset_id,
                component_name: digispace.properties.filter((property) => property.meta)[0].value,
                live_response: true
                
              }
            : {
                asset_id: asset_id,
                display_id: digispace.properties[2].value
              
              } 
          
          ),
          type: "SET",
          value: muted ? "on": "off"
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
      commandRequest(dispatch,commandRequestPayload)
    })
  }

  return (
    <div className={styles.volume} onClick={handleClick} >
      { muted 
        ? <IconComponent name="volume-x" width={24} height={24} color={"#ffffff"}/>
        : <IconComponent name="volume-max" width={24} height={24} color={"#ffffff"}/>
      }
    </div>
  )
}

export default Volume