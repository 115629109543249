import { createContext, useReducer } from "react";
import initialState from "./state/state";
import authReducer from "./reducers/authReducer";
import authInitialState from "./state/authInitialState";

export const AuthContext = createContext(null)

const AuthProvider = ({children}) => {
  const [authState, authDispatch] = useReducer(authReducer,authInitialState)

  return (
    <AuthContext.Provider value={{authState, authDispatch}}>
      {children}
    </AuthContext.Provider>
  )
}
export default AuthProvider