import { createContext, useReducer } from "react";
import initialState from "./state/state";
import reducer from "./reducers/reducer";

export const GlobalContext = createContext(null)

const GlobalProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer,initialState)

  return (
    <GlobalContext.Provider value={{state,dispatch}}>
      {children}
    </GlobalContext.Provider>
  )
}
export default GlobalProvider

