import axios from "axios"
import http from "../../services/httpService";
import { API_BASE_URL } from "../../constants/apiConstants"
import { setLocalStorage } from "../../utils/setLocalStorage"

export const login = (dispatch,credentials) => { 
  try {
    dispatch({
      type: 'LOGIN_REQUEST',
      // payload: credentials
    })
    
    http.post(`${API_BASE_URL}/auth/GetAuthUser`,credentials)
    .then((res) => {
      const {data} = res
      if(res.data.success===true){
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: res.data
      })
        setLocalStorage(data)
      }else{
        dispatch({
          type: 'LOGIN_FAIL'})
      }
    })
  } catch (error) {
   
  }
}

export const logout = (dispatch) => { 
  dispatch({type:"LOGOUT"})
  localStorage.clear()
}

