import React from 'react'
import styles from "./bottomGrid.module.css"
const BottomGrid = ({children}) => {
  return (
    <div className={`${styles.grid} has-scrollbar`}>
      {children}
    </div>
  )
}

export default BottomGrid