import React, { useState } from "react";
import IconComponent from "../../common/icons/IconComponent";
import styles from "./audio.module.css";
import Dropdown from "../../common/dropdown/Dropdown";
import Slider from "../../common/slider/Slider";
import Volume from "../../common/volume/Volume";

const Audio = ({
  setOpenSidebar,
  setComponents,
  setSidebarColor,
  setCurrentSidebar,
  source,
  setSource,
  deviceOn,
  setDeviceOn,
  audio,
  deviceDetail
}) => {
  // const [selectedOption, setSelectedOption] = useState(source)
  const options = deviceDetail.digispace.properties.slice(-1)[0].value.map((dropdownValue,index) => dropdownValue.friendly_name);;
  
  /* handleClick passes all the data for Audio sidebar to render */
  const handleClick = () => {
    setOpenSidebar(true);
    setCurrentSidebar('Audio')
    setComponents({
      status: deviceOn ? "Device is On" : "Device is Off",
      statusIcon: deviceOn ? (
        <IconComponent
          name="ellipse"
          width={16}
          height={16}
          color={"#31BF8C"}
        />
      ) : (
        <IconComponent
          name="ellipse"
          width={16}
          height={16}
          color={"#F3C624"}
        />
      ),
      icon: "speaker",
      title: "Audio Controller",
      dropdown: (
        <Dropdown
          options={options}
          selectedOption={source}
          setSelectedOption={setSource}
          deviceData={deviceDetail}
        />
      ),
      slider: (
        <Slider
        sliderLevel={
          deviceDetail.device.properties[2].control.options 
          ? deviceDetail.device.properties[2].control.options 
          : deviceDetail.device.properties[2].control
        }
        sliderValue={deviceDetail.device.properties[2].value}
        deviceData={deviceDetail}
        />
      ),
      volume: (
        <Volume
          volumeStatus={
            deviceDetail.device.properties[1].value &&
            deviceDetail.device.properties[1].value === "on"
              ? true
              : false
          }
          deviceData={deviceDetail}
        />
      ),
      device: deviceDetail.asset_name,
    });
    setSidebarColor(deviceOn ? "#8D79DB" : "#BFBFBF");
  };

  return (
    <div className="card-widget-devices" onClick={handleClick}>
      {/* Device Heading */}
      <div className="card-widget-devices__titleRow">
        <div className="card-widget-devices__heading">
          <IconComponent
            name="speaker"
            width={16}
            height={16}
            color={"#8D79DB"}
          />
          <h4 className="card-widget-devices__title">{deviceDetail.asset_name}</h4>
        </div>
        <div
          style={{
            background: "rgba(141, 121, 219, 0.1)",
            borderRadius: 100,
            width: 20,
            height: 20,
            padding: "1px 4px",
          }}
        >
          <IconComponent
            name={deviceDetail.device.properties[1].value === 'on' ? 'volume-x': 'volume-max'}
            width={12}
            height={12}
            color={"#8D79DB"}
          />
        </div>
      </div>

      {/* Device data */}
      <div className={styles.cardWidgetDevices__content}>
        <img src="/Audio.png" alt="songDisplay" />
        <div className={styles.cardWidgetDevices__content__data}>
          <span className={styles.cardWidgetDevices__content__song}>
            {audio.song}
          </span>
          <span className={styles.cardWidgetDevices__content__artist}>
            {audio.artist}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Audio;
