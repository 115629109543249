import React, { useContext, useEffect, useState } from 'react'
import IconComponent from '../icons/IconComponent'
import styles from "./dropdown.module.css";
import { commandRequest } from '../../../context/actions/zoneActions';
import { GlobalContext } from '../../../context/provider';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const Dropdown = ({options,selectedOption,setSelectedOption,deviceData:{device,digispace,asset_id,id}}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const [isActive, setisActive] = useState(false)
  const [selected, setselected] = useStateWithCallbackLazy(selectedOption)  

  const handleSelect = (option) => {
    setselected(option,(option) => {
      const commandRequestPayload = {
        AssetId: id,
        Properties: device.properties.map((property,index) => {
          if(property.label === 'source'){
            return {
              ...property,
              value: digispace.properties.slice(-1)[0].value.filter((item) => item.friendly_name === option)[0].source
            }
          } else if(property.label === 'input'){
            return {
              ...property,
              value: digispace.properties.slice(-1)[0].value.filter((item) => item.friendly_name === option)[0].input
            }
          } else if(property.label === 'Scene'){
            return {
              ...property,
              value: digispace.properties.slice(-1)[0].value.filter((item) => item.friendly_name === option)[0].scene
            }
          }
          else {
            return property
          }
        }),
        
        RequestCommand: {
          action: (
            device.subtype === 'audio'
            ? "input"
            : device.subtype === 'light'
            ? "scene"
            : "source"
            
          ), 
          action_values: (
            device.subtype === 'audio'
            ? ["level", "mute", "input"]
            : device.subtype === 'light'
            ? ["scene"]
            : ["power", "source", "volume"]
          ), 
          executed: false,
          meta: (
            device.subtype === 'audio' 
            ? {
                asset_id: asset_id,
                component_name: digispace.properties.filter((property) => property.meta)[0].value,
                live_response: true
              }
            : device.subtype === 'light'
            ? {
                asset_id: asset_id,
                scene: digispace.properties.slice(-1)[0].value
              } 
            : {
                asset_id: asset_id,
                display_id: digispace.properties[2].value
              }
          ),
          type: "SET",
          value: digispace.properties.slice(-1)[0].value.filter((item) => item.friendly_name === option)[0].scene
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
      
      commandRequest(dispatch,commandRequestPayload)
    })
    setSelectedOption(option)
    setisActive(!isActive)
  }
  const handleClick = () => {
    setisActive(!isActive)
  }

  return (
    <div className={styles.dropdown} data-testid="dropdown_container">
      <div className={styles.dropdown__btn} onClick={handleClick}>
        <span className={styles.dropdown__selected}> {selected} </span>
        <IconComponent name='chevron-down' width={16} height={16} color={'#ffffff'}/> 
      </div>
      {
        isActive && (
          <div className={styles.dropdown__options}>
            {
              options.map((option,index) => (
                <div 
                  className={styles.dropdown__options__item} 
                  onClick={(e) => handleSelect(option)} 
                  key={index}
                >
                  {option}
                </div>
              ))
            }

          </div>
        )
      }
    </div>
  )
}

export default Dropdown