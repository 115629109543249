const LOGIN_REQUEST= 'LOGIN_REQUEST'
const LOGIN_SUCCESS= 'LOGIN_SUCCESS'
const LOGIN_FAIL= 'LOGIN_FAIL'
const LOGOUT = 'LOGOUT' 
const LOADING="LOADING"
const GET_SITELIST='GET_SITELIST'
const GET_FLOORLIST='GET_FLOORLIST'
const GET_ASSETLIST='GET_ASSETLIST'
const GET_FLOOR='GET_FLOOR'
const GET_FLOOR_ROOMLIST='GET_FLOOR_ROOMLIST'
const GET_FLOOR_ASSETLIST='GET_FLOOR_ASSETLIST'
const GET_BDASSETLIST='GET_BDASSETLIST'
const COMMAND_REQUEST='COMMAND_REQUEST'

export {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_ASSETLIST,
  GET_SITELIST,
  GET_FLOORLIST,
  GET_FLOOR,
  GET_FLOOR_ROOMLIST,
  GET_FLOOR_ASSETLIST,
  GET_BDASSETLIST,
  COMMAND_REQUEST,
  LOADING
}