import React from 'react'
import styles from "./status.module.css";
import IconComponent from '../icons/IconComponent';

const Status = ({deviceStatus,toggleValue}) => {

  return (
    <div className={styles.sidebar__status}>
      {
        deviceStatus 
          ? (
            <>
              <span>Device is On</span>
              <IconComponent name="ellipse" width={16} height={16} color={"#31BF8C"} />
            </>
          )
          : (
            <>
              <span>Device is Off</span>
              <IconComponent name="ellipse" width={16} height={16} color={"#F3C624"} />
            </>
          )
      }
    </div>
  )
}

export default Status