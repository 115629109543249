import IconComponent from "../../components/common/icons/IconComponent";
// initial screen map dashboard
export const mapArea = [
  {
    left: "3.5%",
    top: "11%",
    height: "7%",
    width: "4%",
    id: "1",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "5%",
    top: "24%",
    height: "7%",
    width: "4%",
    id: "2",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "4%",
    top: "44%",
    height: "7%",
    width: "4%",
    id: "3",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "5%",
    top: "60%",
    height: "7%",
    width: "4%",
    id: "4",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "26%",
    top: "19%",
    height: "7%",
    width: "4%",
    id: "5",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "43%",
    top: "68%",
    height: "7%",
    width: "4%",
    id: "6",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "70%",
    top: "60%",
    height: "7.7%",
    width: "4.5%",
    id: "7",
    style: {
      background: "#B575C4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
];
// Airquality section co-odinates maps
export const AirQualityMap = [
  {
    left: "4%",
    top: "12%",
    height: "7.7%",
    width: "4.5%",
    id: "1",
    style: {
      background: "#4BC9B2",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5.3%",
    top: "25%",
    height: "7.7%",
    width: "4.5%",
    id: "2",
    style: {
      background: "#F3C624",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          transform: "rotate(-90deg)",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5%",
    top: "40%",
    height: "7.7%",
    width: "4.5%",
    id: "3",
    style: {
      background: "#4BC9B2",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5.2%",
    top: "62%",
    height: "7.7%",
    width: "4.5%",
    id: "4",
    style: {
      background: "#4BC9B2",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "26%",
    top: "20%",
    height: "7.7%",
    width: "4.5%",
    id: "5",
    style: {
      background: "#F24242",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          transform: "rotate(180deg)",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "41%",
    top: "72%",
    height: "7.7%",
    width: "4.5%",
    id: "6",
    style: {
      background: "#4BC9B2",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "70%",
    top: "60%",
    height: "7.7%",
    width: "4.5%",
    id: "7",
    style: {
      background: "#4BC9B2",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="thumbs-up" color="white" height={19} width={19} />
      </span>
    ),
  },
];

// Temperature Section map co-ordinates

export const TemperatureMap = [
  {
    left: "4%",
    top: "12%",
    height: "7%",
    width: "4%",
    id: "1",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "4%",
    top: "19.5%",
    height: "5.5%",
    width: "3%",
    id: "1",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
  {
    left: "5.5%",
    top: "25%",
    height: "7%",
    width: "4%",
    id: "2",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "5%",
    top: "32%",
    height: "5.5%",
    width: "3%",
    id: "2",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
  {
    left: "5%",
    top: "40%",
    height: "7%",
    width: "4%",
    id: "3",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "5.5%",
    top: "62%",
    height: "7%",
    width: "4%",
    id: "4",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "6%",
    top: "72%",
    height: "5.5%",
    width: "3%",
    id: "4",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
  {
    left: "25%",
    top: "19%",
    height: "7%",
    width: "4%",
    id: "5",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "35.5%",
    top: "16%",
    height: "5.5%",
    width: "3%",
    id: "5",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
  {
    left: "42%",
    top: "72%",
    height: "7%",
    width: "4%",
    id: "6",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "35.5%",
    top: "84%",
    height: "5.5%",
    width: "3%",
    id: "6",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
  {
    left: "48%",
    top: "66%",
    height: "5.5%",
    width: "3%",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
  {
    left: "70%",
    top: "60%",
    height: "7.7%",
    width: "4.5%",
    id: "7",
    style: {
      background: "#FE5D70",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  {
    left: "85%",
    top: "52%",
    height: "5.5%",
    width: "3%",
    id: "7",
    style: {
      background: "#FE5D70",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <p>21</p>
      </span>
    ),
  },
];

export const DisplaysMap = [
  {
    left: "3.5%",
    top: "11%",
    height: "7%",
    width: "4%",
    id: "1",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "5%",
    top: "24%",
    height: "7%",
    width: "4%",
    id: "2",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "5.5%",
    top: "40%",
    height: "7%",
    width: "4%",
    id: "3",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "6%",
    top: "49%",
    height: "5.5%",
    width: "3.5%",
    id: "3",
    style: {
      background: "#74A1E4",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <IconComponent name="monitor" width={20} height={20} color="white" />
      </span>
    ),
  },
  {
    left: "5.5%",
    top: "62%",
    height: "7%",
    width: "4%",
    id: "4",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "6%",
    top: "56.5%",
    height: "5.5%",
    width: "3.5%",
    id: "3",
    style: {
      background: "#74A1E4",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        <IconComponent name="monitor" width={20} height={20} color="white" />
      </span>
    ),
  },
  {
    left: "26%",
    top: "19%",
    height: "7%",
    width: "4%",
    id: "5",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "43%",
    top: "68%",
    height: "7%",
    width: "4%",
    id: "6",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      border: "2px solid #FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    left: "70%",
    top: "60%",
    height: "7.7%",
    width: "4.5%",
    id: "7",
    style: {
      background: "#74A1E4",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
];
export const LightingMap = [
  {
    left: "4%",
    top: "12%",
    height: "7.7%",
    width: "4.5%",
    id: "1",
    style: {
      background: "#F2BE07",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="lightbulb" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5.3%",
    top: "25%",
    height: "7.7%",
    width: "4.5%",
    id: "2",
    style: {
      background: "#FFD542",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent
          name="lightbulb-03"
          color="white"
          height={19}
          width={19}
        />
      </span>
    ),
  },
  {
    left: "5%",
    top: "40%",
    height: "7.7%",
    width: "4.5%",
    id: "3",
    style: {
      background: "#F2BE07",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="lightbulb" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5.2%",
    top: "62%",
    height: "7.7%",
    width: "4.5%",
    id: "4",
    style: {
      background: "#FFD542",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent
          name="lightbulb-03"
          color="white"
          height={19}
          width={19}
        />
      </span>
    ),
  },
  {
    left: "26%",
    top: "20%",
    height: "7.7%",
    width: "4.5%",
    id: "5",
    style: {
      background: "#F2BE07",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="lightbulb" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "41%",
    top: "72%",
    height: "7.7%",
    width: "4.5%",
    id: "6",
    style: {
      background: "#F2BE07",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="lightbulb" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "70%",
    top: "60%",
    height: "7.7%",
    width: "4.5%",
    id: "7",
    style: {
      background: "#F2BE07",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="lightbulb" color="white" height={19} width={19} />
      </span>
    ),
  },
];

// Occupancy Co-ordinates
export const OccupancyMap = [
  {
    left: "4%",
    top: "12%",
    height: "7.7%",
    width: "4.5%",
    id: "1",
    style: {
      background: "#58BFCA",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="users" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5.3%",
    top: "25%",
    height: "7.7%",
    width: "4.5%",
    id: "2",
    style: {
      background: "#58BFCA",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent
          name="users"
          color="white"
          height={19}
          width={19}
        />
      </span>
    ),
  },
  {
    left: "5%",
    top: "40%",
    height: "7.7%",
    width: "4.5%",
    id: "3",
    style: {
      background: "#A3DFE6",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="users-03" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "5.2%",
    top: "62%",
    height: "7.7%",
    width: "4.5%",
    id: "4",
    style: {
      background: "#A3DFE6",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent
          name="users-03"
          color="white"
          height={19}
          width={19}
        />
      </span>
    ),
  },
  {
    left: "26%",
    top: "20%",
    height: "7.7%",
    width: "4.5%",
    id: "5",
    style: {
      background: "#58BFCA",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="users" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "41%",
    top: "72%",
    height: "7.7%",
    width: "4.5%",
    id: "6",
    style: {
      background: "#58BFCA",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="users" color="white" height={19} width={19} />
      </span>
    ),
  },
  {
    left: "70%",
    top: "60%",
    height: "7.7%",
    width: "4.5%",
    id: "7",
    style: {
      background: "#58BFCA",
      borderRadius: "50%",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      border: "2px solid #FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
        }}
      >
        <IconComponent name="users" color="white" height={19} width={19} />
      </span>
    ),
  },
];
