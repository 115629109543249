import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "../../constants/types";

const authReducer = (state, {type, payload}) => {
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        isAuthenticated: true,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
      case LOGOUT:
        return{
        ...state,
        loading:false,
        data:{},
        isAuthenticated: false,
      }
    default:
      break;
  }
}

export default authReducer