import React, { useContext, useEffect, useState } from "react";
import BottomGrid from "../../components/common/bottomGrid/BottomGrid";
import AirQuality from "../../components/floor/airQuality/AirQuality";
import Temperature from "../../components/floor/temperature/Temperature";
import Lighting from "../../components/floor/lighting/Lighting";
import Displays from "../../components/floor/displays/Displays";
import Occupancy from "../../components/floor/occupancy/Occupancy";
import styles from "./floorpage.module.css";
import Navbar from "../../components/common/navbar/Navbar";
import map from "../../assets/images/map.png";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ImageMap } from "@qiuz/react-image-map";
import IconComponent from "../../components/common/icons/IconComponent";
import {
  DisplaysMap,
  LightingMap,
  OccupancyMap,
  mapArea,
  AirQualityMap,
  TemperatureMap,
 
} from "./mapArea";
import { useNavigate } from "react-router";
import {
  getAssetList,
  getFloor,
  getFloorBasedAssetList,
  getFloorBasedRoomList,
  getFloorList,
  getViewSiteList,
} from "../../context/actions/floorActions";
import { GlobalContext } from "../../context/provider";
import { LOADING } from "../../constants/types";
import { ThreeCircles } from "react-loader-spinner";

const Floor = () => {
  const navigate = useNavigate();
  const [activeWidget, setactiveWidget] = useState();
  const [activeWidgetColor, setActiveWidgetColor] = useState();
  const { state, dispatch } = useContext(GlobalContext);
  const [selectedFloor, setSelectedFloor] = useState(state.floors)
  const [loading, setLoading] = useState(state.loading)
  let subTypeHvacCount = 0
  let subTypelightsCount = 0
  let subTypeScreenCount = 0
  let subTypeAudioCount = 0
  let subTypeRelayDeviceCount = 0


  /* Calls all the api related to floor page */
  useEffect(() => {
    const accountInfo = {
      AccountId: localStorage.getItem("AccountId"),
      SiteId: localStorage.getItem("SiteId"),
    };
    setLoading(true)
    dispatch({type:LOADING})
    getViewSiteList(dispatch, accountInfo)
    getFloorList(dispatch, accountInfo)
    .then(response => {
      dispatch({type:LOADING})
      getFloor(dispatch,{...accountInfo,FloorId:response.res[0].id})
      getFloorBasedRoomList(dispatch,{...accountInfo,FloorId:response.res[0].id})
      getFloorBasedAssetList(dispatch,{...accountInfo,FloorId:response.res[0].id})
      setSelectedFloor(response.res[0])
      setLoading(false)
    }) 
    getAssetList(dispatch, accountInfo)
  }, []);
  /* Redirect to zone */
  const redirect = (area) => {
  
    
    navigate("/zone", { 
      state: {
        areaId: area.id, 
        floor: state.floor.res,
        roomName: area.roomName,
        
      } 
    });

  };
  const areas = state.floorRooms?.res?.map((room) => (
    { 
      id: room.id,
      roomName: room.roomName,
      styles:room.roomstyle
    }
  ))
  /* To customize the dot area object to render the dots */
  const customDotArea = areas?.map((area,index) => (
    {
      id: area.id,
      roomName: area.roomName,
      width: area.styles.width.substring(0,area.styles.width.length - 1 ),
      left: parseInt(area.styles.cx)/10 + '%',
      top: parseInt(area.styles.cy)/6.4+ '%',
      height: area.styles.height.substring(0,area.styles.height.length - 1 ),
      style: {
        background:'#B575C4',
        // background: area.styles.background_color.substring(0,area.styles.background_color.length - 1 ),
        borderRadius: area.styles.border_radius.substring(0,area.styles.border_radius.length - 1 ),
        border: "2px solid #FFFFFF",
        boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      }
    }
  ))
// filter Hvac assets
 const HvacArea=state.floorAssets?.res?.filter((asset)=>asset.device.subtype === 'hvac')

//  create Havc area as per co-ordinates
 const HvacDotArea = HvacArea?.map((area,index) => (
  {
    id: area.room,
    width: 25,
    left: parseInt(area.assetstyle.left) / 10 + "%",
    top: parseInt(area.assetstyle.top) / 6.4 + "%",
    height: 25,
    style: {
      border: "2px solid #FFFFFF",
    background: area.device.properties[0].value === 'on' ? "#FE5D70": '#FE8D9A',
    borderRadius: "100%",
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
       <IconComponent name="thermometer" width={10} height={10} color="white" />
      </span>
    )
  }
))

// filter Screen assets from floorAssets
const ScreenArea=state.floorAssets?.res?.filter((asset)=>asset.device.subtype === 'screen')

//  create screen dots areas as per cordinates
const ScreenDotArea = ScreenArea?.map((area,index) => (
 {
   id: area.room,
   width: 25,
   left: parseInt(area.assetstyle.left) / 10 + "%",
   top: parseInt(area.assetstyle.top) / 6.4 + "%",
   height: 25,
   style: {
   background: area.device.properties[0].value === 'on' ? "#74A1E4": '#c7d9f4',
   border: "2px solid #FFFFFF",
   borderRadius: "100%",
   boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "center",
   },
   render: (area, index) => (
     <span
       style={{
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         border: 0,
         background: "transparent",
         color: "white",
         fontSize: "14px",
       }}
     >
      <IconComponent name="monitor" width={10} height={10} color="white" />
     </span>
   )
 }
))
// filter light assets from floorAssets
const LightArea=state.floorAssets?.res?.filter((asset)=>asset.device.subtype === 'light')

//create light area assets from floorAssets
const LightDotArea = LightArea?.map((area,index) => (
 {
   id: area.room,
   status: area.device.properties[0].value,
   width: 25,
   left: parseInt(area.assetstyle.left) / 10 + "%",
   top: parseInt(area.assetstyle.top) / 6.4 + "%",
   height: 25,
   style: {
   background: area.device.properties[0].value === 'on' ? "#F2BE07": "#FFD542",
   borderRadius: "100%",
   border: "2px solid #FFFFFF",
   boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "center",
   },
   render: (area, index) => (
     <span
       style={{
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         border: 0,
         background: "transparent",
         color: "white",
         fontSize: "14px",
       }}
     >
      <IconComponent name= {area.status === 'on' ? "lightbulb": 'lightbulb-03'} width={10} height={10} color="white" />
     </span>
   )
 }
))
  /* Renders loading screen */
  if (loading) return (
    <div className={styles.loading}>
      <ThreeCircles
        height="100"
        width="100"
        color="red"
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="orange"
        innerCircleColor="white"
        middleCircleColor="green"
      />
      <h3>Loading....</h3>
    </div>
  )
  

  return (
    <div className="page__container">
      <div className="top__container">
        {/* Top Navbar */}
        <Navbar
          activeWidget={activeWidget}
          setactiveWidget={setactiveWidget}
          activeWidgetColor={activeWidgetColor}
          selectedFloor={selectedFloor}
          floors={state.floors && state.floors}
          siteList={state.viewSiteList?.res?.name}
          sidebar={"Floors"}
        />
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={1}
          defaultPositionY={1}
        >
          {/* zoom pan pinch inbuilt handler function render */}
          {({
            zoomIn,
            zoomOut,
            centerView,
            resetTransform,
            zoomToElement,
            ...rest
          }) => (
            <>
              {/* map container  */}
              <div className={styles.map_container}>
                <div className={styles.map_Subcontainer}>
                  <TransformComponent
                    wrapperStyle={{
                      minWidth: "100%",
                      minHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ImageMap
                      className={styles.usage_map}
                      src={state.floor?.res?.FloorImage}
                      map={
                        activeWidget === "Air Quality"
                          ? AirQualityMap
                          : activeWidget === "Temperature"
                          ? HvacDotArea
                          : activeWidget === "Displays"
                          ? ScreenDotArea
                          : activeWidget === "Lighting"
                          ? LightDotArea
                          : activeWidget === "Occupancy"
                          ? OccupancyMap
                          : customDotArea
                      }
                      onMapClick={(area) => {
                        redirect(area);
                      }}
                    />
                  </TransformComponent>
                </div>
                <div className={styles.filter_container}>
                  {/* zoom button */}
                  <button className={styles.btn} onClick={() => zoomIn()}>
                    <IconComponent
                      name="plus-circle"
                      width={24}
                      height={24}
                      color="rgba(24, 24, 24, 0.6)"
                    />
                  </button>
                  {/* zoomout Button */}
                  <button className={styles.btn} onClick={() => zoomOut()}>
                    <IconComponent
                      name="minus-circle"
                      width={24}
                      height={24}
                      color="rgba(24, 24, 24, 0.6)"
                    />
                  </button>
                  {/* navigate reset button */}
                  <button
                    className={styles.btn}
                    onClick={() => resetTransform()}
                  >
                    <IconComponent
                      name="navigation-pointer"
                      width={24}
                      height={24}
                      color="rgba(24, 24, 24, 0.6)"
                    />
                  </button>
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
      <div className="grid__container">
        {/* Reusable Bottom grid */}
        <BottomGrid>
          {
          
            state.floorAssets?.res?.map((asset,index) => {
              if (asset.device.subtype === 'hvac') {
                subTypeHvacCount++
                if(subTypeHvacCount === 1 ){
                  return (
                    <Temperature
                      activeWidget={activeWidget}
                      setactiveWidget={setactiveWidget}
                      setActiveWidgetColor={setActiveWidgetColor}
                    />
                  )
                }
              }else if(asset.device.subtype === 'screen'){
                subTypeScreenCount++
                if (subTypeScreenCount === 1) {
                  return(
                    <Displays
                      activeWidget={activeWidget}
                      setactiveWidget={setactiveWidget}
                      setActiveWidgetColor={setActiveWidgetColor}
                    />
                  )
                }
              }else if(asset.device.subtype === 'light'){
                subTypelightsCount++
                if (subTypelightsCount === 1) {
                  return(
                    <Lighting
                      activeWidget={activeWidget}
                      setactiveWidget={setactiveWidget}
                      setActiveWidgetColor={setActiveWidgetColor}
                    />
                  )                  
                }
              } else if(asset.device.subtype === 'audio'){
                return null
              }
            })
          }
          {/* <AirQuality
            activeWidget={activeWidget}
            setactiveWidget={setactiveWidget}
            setActiveWidgetColor={setActiveWidgetColor}
          />
          <Temperature
            activeWidget={activeWidget}
            setactiveWidget={setactiveWidget}
            setActiveWidgetColor={setActiveWidgetColor}
          />
          <Displays
            activeWidget={activeWidget}
            setactiveWidget={setactiveWidget}
            setActiveWidgetColor={setActiveWidgetColor}
          />
          <Lighting
            activeWidget={activeWidget}
            setactiveWidget={setactiveWidget}
            setActiveWidgetColor={setActiveWidgetColor}
          />
          <Occupancy
            activeWidget={activeWidget}
            setactiveWidget={setactiveWidget}
            setActiveWidgetColor={setActiveWidgetColor}
          /> */}
        </BottomGrid>
      </div>
    </div>
  );
};
export default Floor;
