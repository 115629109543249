import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './slider.module.css'
import { commandRequest } from '../../../context/actions/zoneActions'
import { GlobalContext } from '../../../context/provider';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const Slider = ({sliderLevel:{max,min,step},sliderValue,deviceData}) => {

  const { state, dispatch } = useContext(GlobalContext);
  const [value, setValue] = useStateWithCallbackLazy(sliderValue ? sliderValue : min)

  const handleChange = (e) => {
    setValue(e.target.valueAsNumber,(value) => {
      const commandRequestPayload = {
        AssetId: deviceData.id,
        Properties: deviceData.device.properties.map((property,index) => {
          if(property.label === 'audiolevel'){
            return {
              ...property,
              value: value 
            }
          }
          else {
            return property
          }
        }),
        RequestCommand: {
          action: "audiolevel",
          action_values: ["power", "source", "volume"],
          executed: false,
          meta:{
            asset_id: deviceData.asset_id,
            display_id: deviceData.digispace.properties[2].value
          },
          type: "SET",
          value: value 
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
   
      commandRequest(dispatch,commandRequestPayload)
  
    })
  }


  return (
    <>
      <input
        className={styles.slider}
        style={{
          background: `linear-gradient(90deg, #F5F5F5 ${value}%, #0000001C ${value}%)`
        }}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

export default Slider