import React, { useContext, useEffect, useRef, useState } from 'react'
import AQIElement from '../../components/devices/aqiElement/AQIElement';
import AirConditioner from '../../components/devices/airConditioner/AirConditioner';
import DisplayScreen from '../../components/devices/displayScreen/DisplayScreen';
import Audio from '../../components/devices/audio/Audio';
import Lights from '../../components/devices/lights/Lights';
import Blinds from '../../components/devices/blinds/Blinds';
import BottomGrid from '../../components/common/bottomGrid/BottomGrid';
import Navbar from '../../components/common/navbar/Navbar';
import Sidebar from '../../components/common/sidebar/Sidebar';
import IconComponent from '../../components/common/icons/IconComponent';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { ImageMap } from '@qiuz/react-image-map';
import styles from "./Zone.module.css"
import { useLocation } from 'react-router-dom';
import OutsideClick from './OutsideClick';
import Status from '../../components/common/status/Status';
import { GlobalContext } from '../../context/provider';
import { LOADING } from '../../constants/types';
import { getBdAssetList } from '../../context/actions/zoneActions';
import { ThreeCircles } from 'react-loader-spinner';
import { getFloorBasedAssetList, getFloorBasedRoomList } from '../../context/actions/floorActions';

const Zone = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const transformComponentRef = useRef(null);
  const location = useLocation();
  const [activeWidget, setactiveWidget] = useState();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [components, setComponents] = useState({});
  const [sidebarColor, setSidebarColor] = useState();
  const [aqiOn, setAqiOn] = useState(true);
  const [screenDeviceOn, setScreenDeviceOn] = useState(true);
  const [airConditionerOn, setAirConditionerOn] = useState(true);
  const [audioOn, setAudioOn] = useState(true);
  const [lightsOn, setLightsOn] = useState(true);
  const [blindsOn, setBlindsOn] = useState(true);
  const [currentSidebar, setCurrentSidebar] = useState();
  // const [deviceStatus, setDeviceStatus] = useState("Device is On")
  const [screenSource, setscreenSource] = useState();
  const [audioSource, setAudioSource] = useState();
  const [lightSource, setLightSource] = useState();
  const [setPointValue, setSetPointValue] = useState(18);
  const sidebarRef = useRef(null);
  const outsideClick = OutsideClick(sidebarRef, setOpenSidebar);
  const [selectedFloor, setSelectedFloor] = useState(location.state.floor);
  const [loading, setLoading] = useState(state.loading);
  const [area, setArea] = useState(location.state.areaId);

  useEffect(() => {
    const zoneInfo = {
      AccountId: localStorage.getItem("AccountId"),
      SiteId: localStorage.getItem("SiteId"),
      RoomId: location.state.areaId
    }
    setLoading(true)
    dispatch({type:LOADING})
    getBdAssetList(dispatch,zoneInfo)
    setLoading(false)
    const intervalId = setInterval(() => {
      const floorInfo = {
        AccountId: localStorage.getItem("AccountId"),
        SiteId: localStorage.getItem("SiteId"),
        FloorId: location.state.floor.id
      }
      getFloorBasedRoomList(dispatch,floorInfo)
      getFloorBasedAssetList(dispatch,floorInfo)
    }, 30000);
    return () => {
      clearInterval(intervalId)
    }
   
  }, [])

  const room = state.floorRooms.res.filter((room) => room.id === location.state.areaId)

  const areas = state.zoneBdAssets?.res?.map((zone) => ({
    id: zone.room,
    styles: zone.assetstyle,
  }));
  
  /* To customize the dot area object to render the dots */
  const customDotArea = areas?.map((area, index) => ({
    id: area.id,
    width: 20,
    left: parseInt(area.styles.left) / 10 + "%",
    top: parseInt(area.styles.top) / 6.4 + "%",
    height: 20,
    icon: area.styles.assetsvg,
    style: {
      background:
        area.styles.assetsvg === "guest:ac_icon"
          ? "#FE5D70"
          : area.styles.assetsvg === "guest:audio_icon"
          ? "#8D79DB"
          : area.styles.assetsvg === "guest:lights_icon"
          ? "#F2BE07"
          : area.styles.assetsvg === "guest:display_icon"
          ? "#74A1E4"
          : area.styles.assetsvg === "guest:blinds_icon"
          ? "#EB719D"
          : "grey",
      borderRadius: "5px",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    render: (area, index) => (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          background: "transparent",
          color: "white",
          fontSize: "14px",
        }}
      >
        {area.icon === "guest:ac_icon" ? (
          <IconComponent
            name="thermometer"
            width={10}
            height={10}
            color="white"
          />
        ) : area.icon === "guest:audio_icon" ? (
          <IconComponent name="speaker" width={10} height={10} color="white" />
        ) : area.icon === "guest:lights_icon" ? (
          <IconComponent
            name="lightbulb"
            width={10}
            height={10}
            color="white"
          />
        ) : area.icon === "guest:display_icon" ? (
          <IconComponent name="monitor" width={10} height={10} color="white" />
        ) : area.icon === "guest:blinds_icon" ? (
          <IconComponent name="blinds" width={10} height={10} color="white" />
        ) : null}
      </span>
    ),
  }));
//  ZoomToImage  call when page onLoad call zoomToElement function
  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      zoomToElement(area, 2);
    }
  };

  /* Renders loading screen */
  if (loading)
    return (
      <div className={styles.loading}>
        <ThreeCircles
          height="100"
          width="100"
          color="red"
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor="orange"
          innerCircleColor="white"
          middleCircleColor="green"
        />
        <h3>Loading....</h3>
      </div>
    );
  return (
    <div className="page__container">
      {/* Reusable & customizable Sidebar */}
      {openSidebar && (
        <Sidebar color={sidebarColor} ref={sidebarRef}>
          {/* Sidebar Device Status */}
          <Status
            deviceStatus={
              currentSidebar === "Air Conditioner"
                ? airConditionerOn
                : currentSidebar === "Display Screen"
                ? screenDeviceOn
                : currentSidebar === "Audio"
                ? audioOn
                : currentSidebar === "Lights"
                ? lightsOn
                : currentSidebar === "Blinds"
                ? blindsOn
                : aqiOn
            }
          />
          {/* Title & device Name */}
          <div className="sidebar__titleContainer">
            <div className="sidebar__title">
              <IconComponent
                name={components.icon}
                width={16}
                height={16}
                color={"#FFFFFF99"}
              />
              <span>{components.title}</span>
            </div>
            <h2 className="sidebar__titleContainer__device">
              {components.device}
            </h2>
            {
              /* Temperature Data for Air Conditioner Sidebar */
              components.temp ? (
                <div className="sidebar__titleContainer__temp">
                  <span className="sidebar__titleContainer__tempValue">
                    {components.temp}
                  </span>
                  <span className="sidebar__titleContainer__tempUnit">
                    &deg;C
                  </span>
                </div>
              ) : components.scene ? (
                <span className="sidebar__titleContainer__sceneText">
                  {components.scene}
                </span>
              ) : null
            }
          </div>
          {
            /* For Air Quality Data */
            components.airQualityData ? (
              <div className="sidebar__airQualityData">
                <span className="sidebar__airQualityData__component">
                  {components.airQualityData.airComponent}
                </span>
                <div className="sidebar__airQualityData__valueCont">
                  <span className="sidebar__airQualityData__value">
                    {components.airQualityData.value}
                  </span>
                  <span className="sidebar__airQualityData__unit">
                    {components.airQualityData.unit}
                  </span>
                </div>
              </div>
            ) : (
              /* Device Controls */
              <div className="sidebar__deviceControl">
                {components.counter && components.counter}
                {components.dropdown && components.dropdown}
                {components.slider && components.slider}
                <div className="sidebar__deviceControl__toggle">
                  {components.toggle && components.toggle}
                  {components.volume && components.volume}
                </div>
                {components.blindControls && components.blindControls}
              </div>
            )
          }
          {
            /* Bottom Description for Air Quality Sidebar */
            components.description && (
              <div className="sidebar__bottomDescription">
                <IconComponent
                  name={components.description.icon}
                  width={40}
                  height={40}
                  color={"#FFFFFF"}
                />
                <span>{components.description.desc}</span>
              </div>
            )
          }
        </Sidebar>
      )}

      <div className="top__container">
       
        <TransformWrapper
          ref={transformComponentRef}
          initialScale={1}
          defaultPositionX={1}
          defaultPositionY={1}
          disabled={true}
        >
          {/* zoom pan pinch inbuilt handler function render */}
          {/* early we destructure all the inbuilt function direct onLoad page call that zoomToelement function directly */}
          {/* now we need to achieve zoomToelement function call from another components  */}
          {/* follow library turtorial link https://www.npmjs.com/package/react-zoom-pan-pinch*/}
          {/* {({zoomToElement,...rest}) => ( */}
          {(utils) => (
            <>
              {/* map container  */}
               {/* Top Navbar */}
              <Navbar
                selectedFloor={selectedFloor}
                floors={state.floors && state.floors}
                siteList={JSON.parse(localStorage.getItem('currentUser')).viewSiteListName}            
                onZone={room[0]?.roomName}
                sidebar={"Zones"}
                {...utils}
              />
              <div className={styles.map_container}>
                <div className={styles.map_Subcontainer}>
                  <TransformComponent
                    wrapperStyle={{
                      minWidth: "100%",
                      minHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ImageMap
                      className={styles.usage_map}
                      src={location.state.floor.FloorImage}
                      map={customDotArea}
                      // when Zone page reload then this function calls
                      onLoad={() => {
                        // call ZoomToImage function after 1 sec of mounting page
                        setTimeout(() => {
                          zoomToImage();
                        }, 1000);
                      }}
                    />
                  </TransformComponent>
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
      {openSidebar && !outsideClick ? null : (
        <div className="grid__container">
          {/* Reusable Bottom grid */}
          <BottomGrid>
            {state.zoneBdAssets?.res?.map((asset, index) =>
              asset.device.subtype === "hvac" ? (
                <AirConditioner
                  activeWidget={activeWidget}
                  setactiveWidget={setactiveWidget}
                  setOpenSidebar={setOpenSidebar}
                  sidebarColor={sidebarColor}
                  setSidebarColor={setSidebarColor}
                  setCurrentSidebar={setCurrentSidebar}
                  setComponents={setComponents}
                  setPointValue={setPointValue}
                  setSetPointValue={setSetPointValue}
                  deviceOn={
                    asset.device.properties[0].value === "on" ? true : false
                  }
                  setDeviceOn={setAirConditionerOn}
                  deviceDetail={asset}
                />
              ) : asset.device.subtype === "screen" ? (
                <DisplayScreen
                  activeWidget={activeWidget}
                  setactiveWidget={setactiveWidget}
                  setOpenSidebar={setOpenSidebar}
                  sidebarColor={sidebarColor}
                  setSidebarColor={setSidebarColor}
                  setCurrentSidebar={setCurrentSidebar}
                  setComponents={setComponents}
                  components={components}
                  deviceOn={
                    asset.device.properties[0].value === "on" ? true : false
                  }
                  setDeviceOn={setScreenDeviceOn}
                  source={
                    asset.digispace.properties.slice(-1)[0].value[0]
                      .friendly_name || screenSource
                  }
                  setSource={setscreenSource}
                  deviceDetail={asset}
                />
              ) : asset.device.subtype === "light" ? (
                <Lights
                  activeWidget={activeWidget}
                  setactiveWidget={setactiveWidget}
                  setOpenSidebar={setOpenSidebar}
                  setSidebarColor={setSidebarColor}
                  setCurrentSidebar={setCurrentSidebar}
                  setComponents={setComponents}
                  deviceOn={asset.device.subtype === "light" ? true : false}
                  setDeviceOn={setLightsOn}
                  source={
                    lightSource ||
                    asset.digispace.properties.slice(-1)[0].value[0]
                      .friendly_name
                  }
                  setSource={setLightSource}
                  deviceDetail={asset}
                />
              ) : asset.device.subtype === "audio" ? (
                <Audio
                  activeWidget={activeWidget}
                  setactiveWidget={setactiveWidget}
                  setOpenSidebar={setOpenSidebar}
                  setSidebarColor={setSidebarColor}
                  setCurrentSidebar={setCurrentSidebar}
                  setComponents={setComponents}
                  deviceOn={audioOn}
                  setDeviceOn={setAudioOn}
                  source={
                    asset.digispace.properties.slice(-1)[0].value[0]
                      .friendly_name || audioSource
                  }
                  setSource={setAudioSource}
                  audio={{
                    song: "This Is Why",
                    artist: "Paramore",
                  }}
                  deviceDetail={asset}
                />
              ) : asset.device.subtype === "Relay Device" ? (
                <Blinds
                  activeWidget={activeWidget}
                  setactiveWidget={setactiveWidget}
                  setOpenSidebar={setOpenSidebar}
                  setSidebarColor={setSidebarColor}
                  setCurrentSidebar={setCurrentSidebar}
                  setComponents={setComponents}
                  deviceOn={blindsOn}
                  setDeviceOn={setBlindsOn}
                  deviceDetail={asset}
                />
              ) : null
            )}
            {/* <AQIElement 
                activeWidget={activeWidget} 
                setactiveWidget={setactiveWidget}
                setOpenSidebar={setOpenSidebar}
                setSidebarColor={setSidebarColor}
                setCurrentSidebar={setCurrentSidebar}
                setComponents={setComponents}
                deviceOn={aqiOn}
                setDeviceOn={setAqiOn}
                deviceType={'AQI Element'}
              />
              <AirConditioner 
                activeWidget={activeWidget} 
                setactiveWidget={setactiveWidget}
                setOpenSidebar={setOpenSidebar}
                sidebarColor={sidebarColor}
                setSidebarColor={setSidebarColor}
                setCurrentSidebar={setCurrentSidebar}
                setComponents={setComponents}
                deviceOn={airConditionerOn}
                setDeviceOn={setAirConditionerOn}
                deviceType={'Air Conditioner'}
              />
            
              <DisplayScreen 
                activeWidget={activeWidget} 
                setactiveWidget={setactiveWidget}
                setOpenSidebar={setOpenSidebar}
                sidebarColor={sidebarColor}
                setSidebarColor={setSidebarColor}
                setCurrentSidebar={setCurrentSidebar}
                setComponents={setComponents}
                components={components}
                deviceOn={screenDeviceOn}
                setDeviceOn={setScreenDeviceOn}
                
                source={screenSource || 'HDMI 1'}
                setSource={setscreenSource}
                deviceType={'Display Screen'}
              />
              <Lights 
                activeWidget={activeWidget} 
                setactiveWidget={setactiveWidget}
                setOpenSidebar={setOpenSidebar}
                setSidebarColor={setSidebarColor}
                setCurrentSidebar={setCurrentSidebar}
                setComponents={setComponents}
                deviceOn={lightsOn}
                setDeviceOn={setLightsOn}
                source={lightSource || 'Scene1'}
                setSource={setLightSource}
                deviceType={'Lights'}
              />
              <Audio 
                activeWidget={activeWidget} 
                setactiveWidget={setactiveWidget}
                setOpenSidebar={setOpenSidebar}
                setSidebarColor={setSidebarColor}
                setCurrentSidebar={setCurrentSidebar}
                setComponents={setComponents}
                deviceOn={audioOn}
                setDeviceOn={setAudioOn}
                source={audioSource || 'Spotify'}
                setSource={setAudioSource}
                audio={{
                  song: 'This Is Why',
                  artist: 'Paramore'
                }}
                deviceType={'Audio'}
              />
              <Blinds 
                activeWidget={activeWidget} 
                setactiveWidget={setactiveWidget}
                setOpenSidebar={setOpenSidebar}
                setSidebarColor={setSidebarColor}
                setCurrentSidebar={setCurrentSidebar}
                setComponents={setComponents}
                deviceOn={blindsOn}
                setDeviceOn={setBlindsOn}
                deviceType={'Blinds'}
              /> */}
          </BottomGrid>
        </div>
      )}
    </div>
  );
};
export default Zone;
