import React, { useContext, useEffect, useState } from 'react'
import styles from './toggleButton.module.css'
import { commandRequest } from '../../../context/actions/zoneActions';
import { GlobalContext } from '../../../context/provider';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const ToggleButton = ({toggle,value,color,setColor,deviceData:{device,digispace,asset_id,id}}) => {
  const [toggleValue, setToggleValue] = useStateWithCallbackLazy(value)
  const { state, dispatch } = useContext(GlobalContext);
  
  /* sets deviceOn for specific device on zone page */
  useEffect(() => {
    
    toggle(toggleValue)
    setColor(toggleValue ? color :'#BFBFBF')
    
  }, [toggleValue,color])
  
  /* Handle change toggle the toggle button */
  const handleChange = () => {
    setToggleValue(!toggleValue,(toggleValue) => {
      const commandRequestPayload = {
        AssetId: id,
        Properties: device.properties.map((property,index) => {
          if(property.label === 'Power'){
            return {
              ...property,
              value: toggleValue ? "on": "off"
            }
          }
          else {
            return property
          }
        }),
        
        RequestCommand: {
          action: "power",
          action_values: device.subtype === 'hvac' ? ["power", "temp"]: ["power", "source", "volume"],
          executed: false,
          meta: device.subtype === 'hvac' ? {
            asset_id: asset_id,
            line_id: digispace.properties[0].value,
            live_response: true,
            unit_id: digispace.properties[1].value
          }:{
            asset_id: asset_id,
            display_id: digispace.properties[2].value
          },
          type: "SET",
          value: toggleValue ? "on": "off"
        },
  
        SiteId: localStorage.getItem("SiteId"),
      }
      
      commandRequest(dispatch,commandRequestPayload)
    })
  }

  return (
    <label className={styles.switch}>
      <input 
        className={styles.toggleInput} 
        type="checkbox" 
        value={toggleValue}
        onChange={handleChange}
        />
      <span className={ toggleValue ? `${styles.switch__slider} ${styles.checked}`: styles.switch__slider} />
    </label>
  )
}

export default ToggleButton