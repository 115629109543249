import React, { useContext, useEffect, useState } from "react";
import styles from "./Login.module.css";
import IconComponent from "../../components/common/icons/IconComponent";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/Input/Input";
import { login } from "../../context/actions/authActions";
import { AuthContext } from "../../context/authProvider";
const Login = () => {
  const navigate = useNavigate();
  // state for handle focus for render border transition on input box
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const {authState,authDispatch} = useContext(AuthContext)
  const isAuth = localStorage.getItem("token") ? true : false;
  useEffect(()=>{
    if(isAuth){
      navigate("/floor")
    }
  })
  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const handleSignin = (e) => {
    e.preventDefault();
    let credentials = {
      email,
      password
    }
    login(authDispatch,credentials)
    setTimeout(() => {
      if(authState.isAuthenticated){
        navigate("/floor",{ replace: true })
      }else{
        setErrorMessage(true)
        setEmail("")
        setPassword("")
      } 
    }, 2000);
   
  };
  // Login Screen components
  return (
    // Container Box
    <div className={styles.container}>
      {/* left container  */}
      <div className={styles.leftcontainer} data-testid="left_container">
        {/* subleft Containet contain all text */}
        <div className={styles.subleftContainer}>
          {/* digispace logo Div */}
          <div className={styles.Logo} data-testid="logo">
            {/* Digispace logo call from IconComponents*/}
            <IconComponent
              name="digiSpace_Logo_BLK"
              width={120}
              height={22}
              color="#FFFFFF"
            />
          </div>
          {/* title container contain text on middle on left container */}
          <div className={styles.titlecontainer}>
            <div className={styles.title_box}>
              <p className={styles.titlefirst} >Hi there,</p>
              <p className={styles.titlesec} data-testid="title">Welcome to DigiSpace</p>
            </div>
            {/* contact detail section */}
            <div className={styles.details}>
              <p>
                Learn more
                <IconComponent
                  name="arrow-circle-broken-up-right"
                  width={14.67}
                  height={14.67}
                  color="rgba(255, 255, 255, 0.6)"
                />
              </p>
              <p>
                Contact Sales{" "}
                <IconComponent
                  name="headphones"
                  width={14.67}
                  height={14.67}
                  color="rgba(255, 255, 255, 0.6)"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* right container */}
      <div className={styles.rightcontainer}>
        <div className={styles.subrightContainer}>
          <div className={styles.login_title}>
            {/* login icon */}
            <IconComponent
              name="log-in-03-alt"
              width={20}
              height={22}
              color="rgba(24, 24, 24, 0.87)"
            />
            {/* login text */}
            <p className={styles.log_text}>Log in</p>
          </div>
          {/* login credential heading */}
          <p className={styles.heading}>
            Please enter your login details below to access your DigiSpace
            account
          </p>
          <form onSubmit={handleSignin}>
            {/* Email Input  */}
            <div className={styles.Input_Container}>
              <Input
                icon="mail-01"
                placeholder="Email address"
                type="email"
                name="email"
                setfocus={setEmailFocus}
                focus={emailFocus}
                value={email}
                handleChange={handleChange}
              />
              {/* password input */}
              <Input
                icon="passcode"
                placeholder="Password"
                type="password"
                name="passcode"
                setfocus={setPasswordFocus}
                focus={passwordFocus}
                value={password}
                handleChange={handleChange}
              />
            </div>
            {errorMessage ? (
              <div className={styles.error_container} data-testid="error_message_container">
                <p>
                  Your username and/or password are incorrect. Please check the
                  details and try again.
                </p>
              </div>
            ) : (
              <div></div>
            )}
            {/* remember passsword or forget password container */}
            <div className={styles.password_remember_container}>
              <p className={styles.remember}>
                <input type="checkbox" id="check"className={styles.remember_check_box} />
                <label for="check" className={styles.remember_text}>Remember me</label>
              </p>
              <p className={styles.forget}>Forgot password?</p>
            </div>
            {/* signIn Button */}
            <button className={styles.sign_btn} data-testid="sign_btn">
              Sign in{" "}
              <IconComponent
                name="log-in-04-alt"
                width={16}
                height={16}
                color="#FFFFFF"
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
